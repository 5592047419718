import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { toast } from 'react-toastify';
export const userActions = {
    userlogin,
    logout,
    uploadImage,
    getAllUser,
    createExpertForm,
    validateOtpNewCompany,
    getCompanyEmployeeList,
    getCompanyEmployeeListTemp,
    getCompanyProfile,
    saveEmplyeeCompany,
    updateEmployee,
    deleteEmployee,
    getClientProfile,
    updateClientProfile,
    disbaleEmployee
};

function getCompanyProfile(data) {

    return dispatch => {
        dispatch(request());
        userService.getCompanyProfile(data)

            .then(
                users => {
                    // console.log("i am in action getCompanyProfile:::", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_COMPANY_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GET_COMPANY_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_COMPANY_PROFILE_FAILURE, error } }
}

function getClientProfile(data) {

    return dispatch => {
        dispatch(request());
        userService.getClientProfile(data)

            .then(
                users => {
                    // console.log("i am in action getClientProfile:::", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_CLIENT_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GET_CLIENT_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_CLIENT_PROFILE_FAILURE, error } }
}

function getCompanyEmployeeList(data, props) {

    return dispatch => {
        dispatch(request());
        userService.getCompanyEmployeeList(data)

            .then(
                users => {
                    dispatch(success(users));
                    // console.log("i am in action getCompanyEmployeeList:::", users);
                    // if (users && users.empolyeeListData && users.empolyeeListData.length > 0) {
                    //     props.history.push({ pathname: '/employeelist' });
                    // }

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.COMPANY_EMPLOYEE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.COMPANY_EMPLOYEE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.COMPANY_EMPLOYEE_LIST_FAILURE, error } }
}



function getCompanyEmployeeListTemp(data, props) {

    return dispatch => {
        dispatch(request());
        userService.getCompanyEmployeeList(data)

            .then(
                users => {
                    dispatch(success(users));
                    // console.log("i am in action getCompanyEmployeeList:::", users);
                    if (users && users.empolyeeListData && users.empolyeeListData.length > 1) {
                        props.history.push({ pathname: '/employeelist' });
                    }

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.COMPANY_EMPLOYEE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.COMPANY_EMPLOYEE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.COMPANY_EMPLOYEE_LIST_FAILURE, error } }
}

function createExpertForm(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.createExpertForm(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    setTimeout(() => {
                        dispatch(reset(user));
                    }, 500);


                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.CREATE_NEWCOMPANY_REQUEST, user } }
    function success(user) { return { type: userConstants.CREATE_NEWCOMPANY_SUCCESS, user } }
    function reset(user) { return { type: userConstants.RESET_NEWCOMPANY_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CREATE_NEWCOMPANY_FAILURE, error } }
}



function saveEmplyeeCompany(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.saveEmplyeeCompany(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Employee add successfully"));
                    // props.history.push('/employeelist');

                    let data = {
                        "keyWord": "",
                        "pageNo": 1,
                        "size": 10
                    }
                    dispatch(this.getCompanyEmployeeList(data));
                    dispatch(this.getCompanyProfile(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.SAVE_EMPLYEE_COMPANY_REQUEST, user } }
    function success(user) { return { type: userConstants.SAVE_EMPLYEE_COMPANY_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SAVE_EMPLYEE_COMPANY_FAILURE, error } }
}

function updateClientProfile(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.updateClientProfile(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Profile Update Successfully."));
                    // props.history.push('/employeelist')
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.UPADTE_CLIENT_PROFILE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPADTE_CLIENT_PROFILE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPADTE_CLIENT_PROFILE_FAILURE, error } }
}
function updateEmployee(data, reqData) {
    return dispatch => {
        dispatch(request({ data }));
        userService.updateEmployee(data)
            .then(
                user => {
                    toast("Update Employee successfully.")
                    dispatch(success(user));
                    dispatch(this.getCompanyEmployeeList(reqData));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_EMPLOYEE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_EMPLOYEE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_EMPLOYEE_FAILURE, error } }
}
function deleteEmployee(data, reqData) {
    return dispatch => {
        dispatch(request({ data }));
        userService.deleteEmployee(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(this.getCompanyEmployeeList(reqData));
                    dispatch(this.getCompanyProfile(reqData));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.DELETE_EMPLOYEE_REQUEST, user } }
    function success(user) { return { type: userConstants.DELETE_EMPLOYEE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.DELETE_EMPLOYEE_FAILURE, error } }
}


function disbaleEmployee(data, reqData) {
    return dispatch => {
        dispatch(request());
        userService.disbaleEmployee(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(this.getCompanyEmployeeList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_EMPLOYEE_REQUEST } }
    function success(user) { return { type: userConstants.DISABLE_EMPLOYEE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.DISABLE_EMPLOYEE_FAILURE, error } }
}

function validateOtpNewCompany(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateOtpNewCompany(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/employeelist`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.USERS_VERIFY_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.USERS_VERIFY_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USERS_VERIFY_OTP_FAILURE, error } }
}

function userlogin(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userlogin(data)
            .then(
                user => {
                    dispatch(success({ user, data }));
                    props.history.push({ pathname: '/verification' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
function getAllUser(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllUser(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}



