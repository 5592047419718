export const userConstants = {
   
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    UPADTE_CLIENT_PROFILE_REQUEST: 'UPADTE_CLIENT_PROFILE_REQUEST',
    UPADTE_CLIENT_PROFILE_SUCCESS: 'UPADTE_CLIENT_PROFILE_SUCCESS',
    UPADTE_CLIENT_PROFILE_FAILURE: 'UPADTE_CLIENT_PROFILE_FAILURE',

    DISABLE_EMPLOYEE_REQUEST: 'DISABLE_EMPLOYEE_REQUEST',
    DISABLE_EMPLOYEE_SUCCESS: 'DISABLE_EMPLOYEE_SUCCESS',
    DISABLE_EMPLOYEE_FAILURE: 'DISABLE_EMPLOYEE_FAILURE',

    GET_CLIENT_PROFILE_REQUEST: 'GET_CLIENT_PROFILE_REQUEST',
    GET_CLIENT_PROFILE_SUCCESS: 'GET_CLIENT_PROFILE_SUCCESS',
    GET_CLIENT_PROFILE_FAILURE: 'GET_CLIENT_PROFILE_FAILURE',

    SAVE_EMPLYEE_COMPANY_REQUEST: 'SAVE_EMPLYEE_COMPANY_REQUEST',
    SAVE_EMPLYEE_COMPANY_SUCCESS: 'SAVE_EMPLYEE_COMPANY_SUCCESS',
    SAVE_EMPLYEE_COMPANY_FAILURE: 'SAVE_EMPLYEE_COMPANY_FAILURE',

    UPDATE_EMPLOYEE_REQUEST: 'UPDATE_EMPLOYEE_REQUEST',
    UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
    UPDATE_EMPLOYEE_FAILURE: 'UPDATE_EMPLOYEE_FAILURE',

    DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
    DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
    DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE',

    CREATE_NEWCOMPANY_REQUEST: 'CREATE_NEWCOMPANY_REQUEST',
    CREATE_NEWCOMPANY_SUCCESS: 'CREATE_NEWCOMPANY_SUCCESS',
    CREATE_NEWCOMPANY_FAILURE: 'CREATE_NEWCOMPANY_FAILURE',

    RESET_NEWCOMPANY_SUCCESS: 'RESET_NEWCOMPANY_SUCCESS',



    USERS_VERIFY_OTP_REQUEST: 'USERS_VERIFY_OTP_REQUEST',
    USERS_VERIFY_OTP_SUCCESS: 'USERS_VERIFY_OTP_SUCCESS',
    USERS_VERIFY_OTP_FAILURE: 'USERS_VERIFY_OTP_FAILURE', 

    COMPANY_EMPLOYEE_LIST_REQUEST: 'COMPANY_EMPLOYEE_LIST_REQUEST',
    COMPANY_EMPLOYEE_LIST_SUCCESS: 'COMPANY_EMPLOYEE_LIST_SUCCESS',
    COMPANY_EMPLOYEE_LIST_FAILURE: 'COMPANY_EMPLOYEE_LIST_FAILURE',

    GET_COMPANY_PROFILE_REQUEST: 'GET_COMPANY_PROFILE_REQUEST',
    GET_COMPANY_PROFILE_SUCCESS: 'GET_COMPANY_PROFILE_SUCCESS',
    GET_COMPANY_PROFILE_FAILURE: 'GET_COMPANY_PROFILE_FAILURE',


    // UPDATES_APP_SETTING_REQUEST: 'UPDATES_APP_SETTING_REQUEST',
    // UPDATES_APP_SETTING_SUCCESS: 'UPDATES_APP_SETTING_SUCCESS',
    // UPDATES_APP_SETTING_FAILURE: 'UPDATES_APP_SETTING_FAILURE',

    // DELETE__APP_SETTING_FAILURE: 'DELETE__APP_SETTING_FAILURE',
    // DELETE__APP_SETTING_SUCCESS: 'DELETE__APP_SETTING_SUCCESS',
    // DELETE__APP_SETTING_REQUEST: 'DELETE__APP_SETTING_REQUEST',

    // DISABLE_APP_SETTING_REQUEST: 'DISABLE_APP_SETTING_REQUEST',
    // DISABLE_APP_SETTING_SUCCESS: 'DISABLE_APP_SETTING_SUCCESS',
    // DISABLE_APP_SETTING_FAILURE: 'DISABLE_APP_SETTING_FAILURE',

};
