import Header from "../component/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef, useState } from "react";
import Footer from "../component/Footer";
import { withRouter } from "react-router-dom";

const faqs = [
  {
    id: 1,
    header: "How do I mint a Happbit NFTs ?",
    text: `
    1. Download and install a Chrome browser plugin called MetaMask. This will allow websites (that you authorize) to access your Ethereum account.

    2. If you have just created a new MetaMask account, buy some MATIC. The MetaMask plugin has a button that will allow you to buy directly from Coinbase. Alternatively, if you already have MATIC in one of your wallets you can transfer it to Metamask.

    3. Once you have the plugin installed, you can click on “Mint now” buttons and connect your Metamask. When the connection is successful, this website will recognize it and add buttons that allow you to buy HAPPBIT NFT directly in the interface.
    `,
  },
  {
    id: 2,
    header: "How do I mint created a new MetaMask account ?",
    text: `If you have just created a new MetaMask account, buy some MATIC. The MetaMask plugin has a button that will allow you to buy directly from Coinbase. Alternatively, if you already have MATIC in one of your wallets you can transfer it to Metamask.`,
  },
  {
    id: 3,
    header: "How do I buy a Happbit NFTs?",
    text: `Once you have the plugin installed, you can click on “Mint now” buttons and connect your Metamask. When the connection is successful, this website will recognize it and add buttons that allow you to buy HAPPBIT NFT directly in the interface.`,
  },
  {
    id: 4,
    header: "What will be the mint price at a public sale?",
    text: `The Mint price at the public sale will depends on the mint date. `,
  },
  {
    id: 5,
    header: "When is the release date?",
    text: `All together, there will be 4 consecutive drops of the HAPPBIT NFT. Which is given in the NFT drop roadmap`,
  },
  {
    id: 6,
    header: "When are the NFTs going to be revealed?",
    text: `All of the HAPPBIT NFTs will be revealed immediately after the release.`,
  },
];

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active1, faq } = props;
  const { header, id, text } = faq;

  return (
    <div
      className="overflow-hidden text-left bg-black rounded-lg cursor-pointer rc-accordion-card bg-opacity-20"
      onClick={() => handleToggle(id)}
    >
      <div className="flex items-center space-x-4 rc-accordion-header">
        <div
          className={`rc-accordion-toggle flex items-center space-x-4 p-6 text-white ${active1 === id ? "active1" : ""
            }`}
        >
          <i className="material-symbols-outlined rc-accordion-icon text-white text-[1rem]">expand_more</i>
          <h5 className="text-lg font-medium text-white rc-accordion-title xl:leading-normal">
            {header}
          </h5>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse text-base text-white  overflow-hidden ${active1 === id ? "show" : ""
          }`}
        style={
          active1 === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="px-6 pb-6 rc-accordion-body ">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

function Home(props) {
  const slider = useRef();

  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingstwo = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    arrows: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsthree = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: -1,
    autoplay: true,
    speed: 4000,
    arrows: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 5,
          slidesToScroll: -1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: -1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: -1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: -1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: -1,
        },
      },
    ],
  };

  const [active1, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active1 === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <div className="bg-gray-800 ">
      <Header />
      {/* section 1 */}
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="py-10 bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="md:h-[calc(100vh_-_14rem)]    md:flex items-center relative container mx-auto 2xl:px-0 px-6  ">
          <div className="flex flex-col-reverse py-10 space-y-10 md:flex-row md:items-center md:space-x-10 md:space-y-0">
            {/* text
              .+8+.-section */}
            <div
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="w-full pt-10 space-y-6 text-center md:text-left md:pt-0 "
            >
              <h1 className="text-4xl font-bold text-white xl:text-5xl xl:leading-normal ">
                Wheel of wonderful life inspires # Happiness NFT
              </h1>
              <p className="text-base text-white lg:text-lg ">
                Collect # Happiness NFT for building your life-time Happiness
                Habits. The true secret of happiness lies in taking a genuine
                interest in all the details of daily life. "William Morris"
              </p>
              <div className="flex justify-center space-x-4 md:justify-start">
                <button
                  //href="/contact-us"
                  class="bg-yellow-500 text-slate-800 px-6 py-3 rounded-full flex items-center text-lg"
                >
                  <a href="https://app.happbit.com/" className="">Mint Now</a>
                  {/* <span class="material-symbols-outlined ml-1">
                    chevron_right
                  </span> */}
                </button>
              </div>
            </div>
            <div
              className="relative flex justify-end w-full"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <div className="flex items-start">
                <img
                  src="\images\happybit\Achievement.png"
                  title="blockchain"
                  alt="monkeybrown"
                  className="left-0 z-10 w-40 transform -translate-y-2 rounded-lg shadow-xl xl:w-60 md:w-56 md:translate-y-20 "
                />
                <img
                  src="\images\happybit\Being.png"
                  title="blockchain"
                  alt="blockchain"
                  className="transform -translate-x-10 -translate-y-20 rounded-lg shadow-xl xl:w-60 md:w-56 w-36 lg:-translate-x-10 md:-translate-x-40 "
                />
                <img
                  src="\images\happybit\Meaning.png"
                  title="blockchain"
                  alt="blockchain"
                  className="left-0 z-20 w-32 transform -translate-x-20 -translate-y-2 rounded-lg shadow-xl xl:w-56 md:w-40 md:translate-y-14 xl:-translate-x-20 lg:-translate-x-40 md:-translate-x-64"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section 2 */}
      <div className="bg-gray-800">
        <div className="container grid grid-cols-2 gap-4 px-6 mx-auto 2xl:px-0 lg:grid-cols-4 md:gap-10 md:py-20 ">
          <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
            <h1 className="text-3xl font-bold text-yellow-500">60 MATIC</h1>
            <p className="text-base font-medium text-white">mint drop #1</p>
          </div>
          <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
            <h1 className="text-3xl font-bold text-yellow-500">70 MATIC</h1>
            <p className="text-base font-medium text-white">mint drop #2</p>
          </div>
          <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
            <h1 className="text-3xl font-bold text-yellow-500">80 MATIC</h1>
            <p className="text-base font-medium text-white">mint drop #3</p>
          </div>
          <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
            <h1 className="text-3xl font-bold text-yellow-500">90 MATIC</h1>
            <p className="text-base font-medium text-white">mint drop #4</p>
          </div>
        </div>
      </div>

      {/* section 3 */}
      <div
        id="about"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative px-6 pt-24 mx-auto space-y-24 md:px-0 lg:space-y-10">
          <div className="flex flex-col items-center justify-center space-y-2">
            <h1 className="py-4 text-3xl font-bold text-white capitalize">
              Our story
            </h1>
            <div className="w-20 border-b-2 border-yellow-500"></div>
          </div>

          <div className="flex flex-col py-10 space-y-16 lg:flex-row md:items-center md:space-x-10 lg:space-y-0">
            <div
              className="flex items-start justify-start w-full md:justify-center"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <img
                src="\images\happybit\Time.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-56 origin-center transform translate-x-10 rounded-lg shadow-xl md:w-80 -rotate-12 md:translate-x-28"
              />
              <img
                src="\images\happybit\Relationship.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover origin-center transform -translate-x-8 -translate-y-20 rounded-lg shadow-xl md:w-56 w-36 rotate-12"
              />
            </div>
            {/* text */}
            <div
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="w-full space-y-6 text-center md:text-left "
            >
              <h1 className="text-2xl font-bold text-white xl:text-5xl xl:leading-normal ">
                Welcome to Happiness Metaverse
              </h1>
              <p className="text-base text-white lg:text-lg ">
                Happbit A unique virtual experience platform for people to build
                habits for happiness & experience mindfulness, strengthen mental
                health.
              </p>
              <p className="text-base text-white lg:text-lg ">
                It helps you build habits that are good for your life but also
                allows you to see the changes it brings to your life.
              </p>
              <p className="text-base text-white lg:text-lg ">
                We want to attract gamers to play habit building game and earn
                happy coin.{" "}
              </p>
              <p className="text-base text-white lg:text-lg ">
                We want to bring Mental health professionals and mindfulness
                coach to Web3 audiences for the first time through NFTs &
                Virtual Experiences - giving them a global platform to engage &
                monetize.
              </p>
              <p className="text-base text-white lg:text-lg ">
                We want to bring NFTs to Life, allowing users to swap happy
                habits NFTs for wellbeing and happiness service.
              </p>
              <div className="flex justify-center space-x-4 md:justify-start">
                <button
                  href="https://discord.gg/HhkMfq24"
                  class="bg-yellow-500 text-slate-800 px-6 py-3 rounded-full flex items-center text-lg"
                >
                  <span class="">Join our Discord</span>
                  <span class="material-symbols-outlined ml-1">
                    chevron_right
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 4 */}
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative px-6 pt-24 mx-auto space-y-10 2xl:px-0">
          <div className="flex flex-col items-center justify-center space-y-2">
            <h1 className="py-4 text-3xl font-bold text-white capitalize">
              NFT Drops Road Map
            </h1>
            <div className="w-20 border-b-2 border-yellow-500"></div>
          </div>
        </div>
      </div>

      <div className="container flex flex-col-reverse px-6 py-10 mx-auto md:flex-row md:items-center md:py-20 md:space-x-20 md:space-y-0 2xl:px-0 ">
        {/* text */}
        <div
          data-aos="fade-right"
          data-aos-delay="50"
          data-aos-duration="1000"
          className="w-full mt-10 space-y-6 text-center md:text-left md:mt-0 "
        >
          <h1 className="text-2xl font-bold text-white xl:text-5xl xl:leading-normal ">
            Happyverse NFT <br /> Distribution.
          </h1>

          <table className="w-full overflow-hidden bg-white rounded-lg">
            <thead>
              <tr className="text-white bg-sky-500">
                <th className="p-2 px-4 text-sm font-medium"> Sn</th>
                <th className="p-2 text-sm font-medium"> Supply</th>
                <th className="p-2 text-sm font-medium"> Mint Start</th>
                <th className="p-2 text-sm font-medium"> Mint</th>
                <th className="p-2 text-sm font-medium"> Status</th>
              </tr>
            </thead>
            <tbody className="text-xs md:text-base">
              <tr className="odd:bg-white even:bg-sky-100">
                <td className="p-2 px-4 font-normal">Drop #1</td>
                <td className="p-2 font-normal">120</td>
                <td className="p-2 font-normal">01 / 2023</td>
                <td className="p-2 font-normal">60 Matic </td>
                <td className="p-2 font-normal">Open</td>
              </tr>
              <tr className="odd:bg-white even:bg-sky-100">
                <td className="p-2 px-4 font-normal">Drop #2</td>
                <td className="p-2 font-normal">240</td>
                <td className="p-2 font-normal">04 / 2023</td>
                <td className="p-2 font-normal">70 Matic </td>
                <td className="p-2 font-normal">upcoming</td>
              </tr>
              <tr className="odd:bg-white even:bg-sky-100">
                <td className="p-2 px-4 font-normal">Drop #3</td>
                <td className="p-2 font-normal">360</td>
                <td className="p-2 font-normal">07 / 2023</td>
                <td className="p-2 font-normal">80 Matic </td>
                <td className="p-2 font-normal">upcoming</td>
              </tr>
              <tr className="odd:bg-white even:bg-sky-100">
                <td className="p-2 px-4 font-normal">Drop #4</td>
                <td className="p-2 font-normal">480</td>
                <td className="p-2 font-normal">10 / 2023</td>
                <td className="p-2 font-normal">90 Matic </td>
                <td className="p-2 font-normal">upcoming</td>
              </tr>
            </tbody>
          </table>

          <p className="text-base text-white lg:text-lg ">
            Next drop will be announded on discord.
          </p>
        </div>

        <div
          className="flex justify-center w-full"
          data-aos="fade-left"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <img
            src="\images\happybit\Worklife.png"
            title="blockchain"
            alt="blockchain"
            className="object-cover w-full md:h-96 rounded-2xl"
          />
        </div>
      </div>

      {/* section 5 */}
      <div
        id="collection"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="relative pt-24 space-y-10 ">
          <div className="flex flex-col items-center justify-center space-y-2">
            <h1 className="py-4 text-3xl font-bold text-white capitalize">
              Our Collection
            </h1>
            <div className="w-20 border-b-2 border-yellow-500"></div>
          </div>

          <div className="py-10 space-y-8 overflow-hidden ">
            <div className="flex space-y-10">
              <Slider {...settingstwo} className="w-full">
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Achievement.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Being.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Meaning.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Relationship.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Time.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Achievement.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Being.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Meaning.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Relationship.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Time.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
              </Slider>
            </div>
            <div className="flex space-y-10">
              <Slider {...settingsthree} className="w-full">
                <div className="px-4">
                  <img
                    src="\images\happybit\Achievement.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Being.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Meaning.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Relationship.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Time.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Achievement.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Being.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Worklife.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Meaning.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Relationship.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
                <div className="px-4">
                  <img
                    src="\images\happybit\Time.png"
                    title="blockchain"
                    alt="blockchain"
                    className="rounded-lg"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* section 6 */}
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="pt-10 bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative items-center px-6 mx-auto md:flex 2xl:px-0">
          <div className="flex flex-col-reverse py-10 space-y-10 lg:flex-row lg:items-center lg:space-x-10 lg:space-y-0">
            {/* text  */}
            <div
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="w-full mt-10 space-y-6 text-center md:text-left md:mt-0"
            >
              <h1 className="text-2xl font-bold text-white xl:text-5xl xl:leading-normal ">
                Metaverse Ready
              </h1>
              <p className="text-base text-white lg:text-lg ">
                Happbit is building state of the art first Mental health and
                Happiness Metaverse platform “Happverse” to provide an immersive
                experience to the users. Happverse a blockchain-enabled
                metaverse platform for people to build habits for happiness &
                experience mindfulness, strengthen mental health and creating a
                new marketing channel for the wellbeing and mental health
                professionals.
              </p>

              <p className="text-base text-white lg:text-lg ">
                Our Happverse will have various habit building zones with
                different themes and activities. It will have mindfulness zones
                where one can practise Yoga with Yoga experts, listen soulful
                music and relax. By virtue of being the first happiness
                Metaverse, Happverse offers many unique and creative
                opportunities for collaboration.{" "}
              </p>

              <p className="text-base text-white lg:text-lg ">
                Our Happverse will host Celebrity Yoga and Mindfulness experts,
                Mental Health coaches and Gaming Zone. We see the Happverse as
                the ultimate platform for happiness and we will collaborate with
                happiness guru and from all over the world - to help them take
                their first steps into Web3.
              </p>
            </div>
            <div
              className="flex justify-center w-full"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <img
                src="/images/nft/metaverse_ready.svg"
                title="blockchain"
                alt="blockchain"
                className="w-full rounded-lg xl:w-4/6"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="roadmap"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative px-6 pt-24 mx-auto space-y-10 2xl:px-0">
          <div className="flex flex-col items-center justify-center space-y-2">
            <h1 className="py-4 text-3xl font-bold text-white capitalize">
              NFT Road map
            </h1>
            <div className="w-20 border-b-2 border-yellow-500"></div>
          </div>
          <div className="space-y-8 overflow-hidden md:py-10">
            {/* Roadmap  */}
            <div className="container w-full h-full mx-auto">
              <div className="relative h-full overflow-hidden wrap md:p-10">
                <div
                  className="absolute h-full border border-2-4 border-sky-500 "
                  style={{ left: "50%" }}
                />
                {/* right timeline */}
                <div className="flex items-center justify-between w-full mb-20 right-timeline ">
                  <div className="order-1 w-11/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center md:-translate-x-48 -translate-x-36">
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q1
                      </div>
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                    </div>
                  </div>

                  <div className="order-1 w-11/12 space-y-4 text-white md:w-5/12 ">
                    <div className="pl-4 space-y-2">
                      <div className="flex items-center space-x-4">
                        <p>Q1 - 2023</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>
                      <h3 className="text-lg font-bold md:text-xl">
                        Happbit NFT release
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        {" "}
                        6 different NFT will be released based on WOW Life
                      </p>
                    </div>
                  </div>
                </div>

                {/* left timeline */}
                <div className="flex flex-row-reverse items-center justify-between w-full mb-20 left-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center translate-x-5 md:translate-x-6">
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q2
                      </div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-right text-white md:w-5/12 ">
                    <div className="pr-4">
                      <div className="flex items-center justify-end space-x-4">
                        <p>Q2 - 2023</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>
                      <h3 className="text-lg font-bold md:text-xl">
                        Premium access to Happbit App
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        {" "}
                        Discounted access to all the premium contents for NFT
                        holders
                      </p>
                    </div>
                  </div>
                </div>
                {/* right timeline */}
                <div className="flex items-center justify-between w-full mb-20 right-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center md:-translate-x-48 -translate-x-36">
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q3
                      </div>
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-white md:w-5/12 ">
                    <div className="pl-4">
                      <div className="flex items-center justify-start space-x-4">
                        <p>Q3 - 2023</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>

                      <h3 className="text-lg font-bold md:text-xl">
                        Build Happy habit in the happbit app for free
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        {" "}
                        Users can build happy habits for free in various
                        dimentions
                      </p>
                    </div>
                  </div>
                </div>

                {/* left timeline */}
                <div className="flex flex-row-reverse items-center justify-between w-full mb-20 left-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center translate-x-5 md:translate-x-6">
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q4
                      </div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-right text-white md:w-5/12 ">
                    <div className="pr-4">
                      <div className="flex items-center justify-end space-x-4">
                        <p>Q4 - 2023</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>
                      <h3 className="text-lg font-bold md:text-xl">
                        Wellbeing store access
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">

                        A wellbeing store will be developed by Happbit based on Happiness and discounted goods and services will be provided to NFT holders
                      </p>
                    </div>
                  </div>
                </div>


                {/* right timeline */}
                <div className="flex items-center justify-between w-full mb-20 right-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center md:-translate-x-48 -translate-x-36">
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q5
                      </div>
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-white md:w-5/12 ">
                    <div className="pl-4">
                      <div className="flex items-center justify-start space-x-4">
                        <p>Q1 - 2024</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>

                      <h3 className="text-lg font-bold md:text-xl">
                        Happiness metaverse session access for holders
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        Time to time metaverse happiness online session will be
                        organised by Happbit. All NFT holders will be given
                        access
                      </p>
                    </div>
                  </div>
                </div>

                {/* left timeline */}
                <div className="flex flex-row-reverse items-center justify-between w-full mb-20 left-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center translate-x-5 md:translate-x-6">
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q6
                      </div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-right text-white md:w-5/12 ">
                    <div className="pr-4">
                      <div className="flex items-center justify-end space-x-4">
                        <p>Q2 - 2024</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>
                      <h3 className="text-lg font-bold md:text-xl">
                        Offline happiness session access
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        {" "}
                        Time to time metaverse happiness offline session will be
                        organised by Happbit. All NFT holders will be given
                        access.
                      </p>
                    </div>
                  </div>
                </div>

                {/* right timeline */}
                <div className="flex items-center justify-between w-full mb-20 right-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center md:-translate-x-48 -translate-x-36">
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q7
                      </div>
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-white md:w-5/12 ">
                    <div className="pl-4">
                      <div className="flex items-center justify-start space-x-4">
                        <p>Q3 - 2024</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>

                      <h3 className="text-lg font-bold md:text-xl">
                        Happbit token airdrop for the nft holders time to time
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        {" "}
                        We will airdrop Happbit token to users.
                      </p>
                    </div>
                  </div>
                </div>

                {/* left timeline */}
                <div className="flex flex-row-reverse items-center justify-between w-full mb-20 left-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center translate-x-5 md:translate-x-6">
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q8
                      </div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-right text-white md:w-5/12 ">
                    <div className="pr-4">
                      <div className="flex items-center justify-end space-x-4">
                        <p>Q4 - 2024</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>
                      <h3 className="text-lg font-bold md:text-xl">
                        Use Happbit token for the booking of Happiness experts
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        Happbit token can be used for Experts booking as well
                      </p>
                    </div>
                  </div>
                </div>

                {/* right timeline */}
                <div className="flex items-center justify-between w-full mb-20 right-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center md:-translate-x-48 -translate-x-36">
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q9
                      </div>
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-white md:w-5/12 ">
                    <div className="pl-4">
                      <div className="flex items-center justify-start space-x-4">
                        <p>Q1 - 2025</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>
                      <h3 className="text-lg font-bold md:text-xl">
                        Metaverse platform for Happiness
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        {" "}
                        We will develop metaverse platform for happbit where NFT
                        holders will get access to various services.
                      </p>
                    </div>
                  </div>
                </div>

                {/* left timeline */}
                <div className="flex flex-row-reverse items-center justify-between w-full mb-20 left-timeline">
                  <div className="order-1 w-9/12 md:w-5/12" />
                  <div className="z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl bg-sky-500">
                    <div className="w-3 h-3 mx-auto bg-white rounded-full"></div>
                    <div className="absolute flex items-center translate-x-5 md:translate-x-6">
                      <div className="w-16 h-2 bg-sky-500 md:w-24"></div>
                      <div className="flex items-center justify-center w-20 h-20 text-xl font-medium text-white rounded-full md:w-24 md:h-24 bg-sky-500 md:text-3xl">
                        Q10
                      </div>
                    </div>
                  </div>
                  <div className="order-1 w-9/12 space-y-4 text-right text-white md:w-5/12 ">
                    {/* <div className='flex items-center justify-end space-x-4'><p className='font-medium'>Q6 - 2023</p> <span className='px-4 py-1 text-sm text-white bg-green-500 rounded-full' >Completed</span></div> */}
                    <div className="pr-4">
                      <div className="flex items-center justify-end space-x-4">
                        <p>Q1 - 2025</p>
                        {/* <span className='px-4 py-1 bg-[#00cd7e] text-white rounded-sm text-sm'>Completed</span> */}
                      </div>
                      <h3 className="text-lg font-bold md:text-xl">
                        Happbit NFT community building{" "}
                      </h3>
                      <p className="text-sm leading-snug tracking-wide text-opacity-100">
                        We will build community around NFT holder and various
                        services will be provided .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Roadmap end */}
          </div>
        </div>
      </div>

      {/* section 8 */}
      <div
        id="holder"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative px-6 pt-24 mx-auto space-y-10 2xl:px-0 md:space-y-20">
          <div className="flex flex-col items-start justify-between space-y-6 md:flex-row md:space-y-0">
            <div>
              <h1 className="py-4 text-3xl font-bold text-white capitalize md:text-5xl">
                Holder benifits
              </h1>
              {/* <p className='text-lg text-white'>The Scort Art Studio a private Collection of NFTs unique difital Collectibles.</p> */}
              <p className="text-base text-white md:text-lg">
                Holders of Happbit NFTs can enjoy special benefits like early
                access to product launches and exclusive entry passes for
                virtual and in-person events,discount on mental health experts
                appointment. Above all, utility NFTs provide access to exclusive
                communities with loads of rewards and incentives
              </p>
            </div>
            <div>
              <div className="flex flex-row space-x-6 md:flex-row ">
                <span
                  onClick={previous}
                  class="material-symbols-outlined rounded-full  p-2 md:px-2 px-4  bg-black bg-opacity-40 text-white "
                >
                  arrow_back
                </span>
                <span
                  onClick={next}
                  class="material-symbols-outlined rounded-full  p-2 md:px-2 px-4  bg-sky-500  text-white "
                >
                  arrow_forward
                </span>
              </div>
            </div>
          </div>

          <div className="flex w-full overflow-hidden text-left">
            <Slider
              ref={(c) => (slider.current = c)}
              {...settings}
              className="flex items-stretch justify-start flex-shrink w-full gap-8 "
            >
              <div className="cursor-pointer md:-mx-4 group ">
                <div className="md:px-4">
                  <div className="p-8 py-10 space-y-6 text-white transition duration-200 border-2 border-sky-500 rounded-2xl group-hover:bg-sky-500 ">
                    <h1 className="text-3xl font-medium line-clamp-2">
                      Premium Access to Happbit App
                    </h1>
                    <p className="text-base line-clamp-3 ">
                      Discounted premium access to all the Happbit NFT holders
                      like Music, Session, Meditation, Yoga, Habit Builder.
                    </p>

                    {/* <ul className="space-y-4 text-base line-clamp-3">
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>

              <div className="cursor-pointer md:-mx-4 group ">
                <div className="md:px-4">
                  <div className="p-8 py-10 space-y-6 text-white transition duration-200 border-2 border-sky-500 rounded-2xl group-hover:bg-sky-500 h-60">
                    <h1 className="text-3xl font-medium line-clamp-2 ">
                      Build happy habit in happbit app for free
                    </h1>
                    <p className="text-base line-clamp-3 ">
                      Users can build happy habits for free in various
                      dimensions like, Time, Being, Achievement, Meaning,
                      Worklife and Relationship.
                    </p>

                    {/* <ul className="space-y-4 text-base line-clamp-3">
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>

              <div className="cursor-pointer md:-mx-4 group ">
                <div className="md:px-4">
                  <div className="p-8 py-10 space-y-6 text-white transition duration-200 border-2 border-sky-500 rounded-2xl group-hover:bg-sky-500 h-60 ">
                    <h1 className="text-3xl font-medium line-clamp-2">
                      Happbit token earn through staking of NFT
                    </h1>
                    <p className="text-base line-clamp-3 ">
                      We have developed a Defi platform where Users can earn
                      Happbit tokens by staking various NFTs.
                    </p>

                    {/* <ul className="space-y-4 text-base line-clamp-3">
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>

              <div className="cursor-pointer md:-mx-4 group ">
                <div className="md:px-4">
                  <div className="p-8 py-10 space-y-6 text-white transition duration-200 border-2 border-sky-500 rounded-2xl group-hover:bg-sky-500 h-60 ">
                    <h1 className="text-3xl font-medium line-clamp-2">
                      Happbit token airdrop time to time
                    </h1>
                    <p className="text-base line-clamp-3 ">
                      We will airdrop Happbit token to users so that they can
                      access various services by burning them.
                    </p>
                    {/* 
                    <ul className="space-y-4 text-base line-clamp-3">
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>

              <div className="cursor-pointer md:-mx-4 group ">
                <div className="md:px-4">
                  <div className="p-8 py-10 space-y-6 text-white transition duration-200 border-2 border-sky-500 rounded-2xl group-hover:bg-sky-500 h-60 ">
                    <h1 className="text-3xl font-medium line-clamp-2 ">
                      Complementary pass to various offline and online happiness
                      session
                    </h1>
                    <p className="text-base line-clamp-3 ">
                      Time to time metaverse happiness offline session will be
                      organised by Happbit. All NFT holders will be given
                      access.
                    </p>

                    {/* <ul className="space-y-4 text-base line-clamp-3">
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>

              <div className="cursor-pointer md:-mx-4 group ">
                <div className="md:px-4">
                  <div className="p-8 py-10 space-y-6 text-white transition duration-200 border-2 border-sky-500 rounded-2xl group-hover:bg-sky-500 h-60 ">
                    <h1 className="text-3xl font-medium line-clamp-2 ">
                      Use nft to unlock metaverse
                    </h1>
                    <p className="text-base line-clamp-3 ">
                      Users can use NFT to unlock various services of upcoming
                      metaverse platform
                    </p>

                    {/* <ul className="space-y-4 text-base">
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                      <li className="flex">
                        <span class="material-icons mr-2">check_circle </span>{" "}
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/* section 9 */}
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative px-6 pt-24 mx-auto space-y-10 2xl:px-0">
          <div className="flex flex-col items-center justify-center space-y-2 text-center">
            <h1 className="py-4 text-3xl font-bold text-white capitalize">
              Happiness and wellbeing
            </h1>
            <div className="w-20 border-b-2 border-yellow-500"></div>
          </div>

          <div className="py-10 space-y-10 lg:flex lg:items-center lg:space-x-10 lg:space-y-0">
            {/* img */}
            <div
              className="flex justify-end w-full"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <img
                src="/images/nft/app.png"
                title="blockchain"
                alt="blockchain"
                className="w-5/6 mr-auto"
              />
            </div>

            {/* text */}
            <div
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="w-full space-y-6 text-center md:text-left "
            >
              <h1 className="text-2xl font-bold text-white xl:text-5xl xl:leading-normal ">
                Companion in your <br />
                Happiness Journey.
              </h1>

              <p className="text-base text-white lg:text-lg ">
                A science backed Happiness & Well Being Platform for bringing
                the best out of individuals. Empower self with positive habits
                and appropriate action for thriving life.
              </p>

              <div className="space-y-6">
                <h1 className="text-xl tracking-wider uppercase text-sky-500 ">
                  feature you will love
                </h1>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                  <div className="flex items-center p-4 space-x-4 border rounded-lg border-sky-500">
                    <img
                      src="/images/nft/wellbeing_habits.png"
                      alt="icons"
                      className="w-14"
                    />
                    <p className="text-base font-medium text-white">
                      Build Happiness and wellbeing Habits
                    </p>
                  </div>
                  <div className="flex items-center p-4 space-x-4 border rounded-lg border-sky-500">
                    <img
                      src="/images/nft/mood_track.png"
                      alt="icons"
                      className="w-14"
                    />
                    <p className="text-base font-medium text-white">
                      Experts to support in your happiness journey
                    </p>
                  </div>
                  <div className="flex items-center p-4 space-x-4 border rounded-lg border-sky-500">
                    <img
                      src="/images/nft/talk_to_experts.png"
                      alt="icons"
                      className="w-14"
                    />
                    <p className="text-base font-medium text-white">
                      Guided meditation to relax your mind
                    </p>
                  </div>
                  <div className="flex items-center p-4 space-x-4 border rounded-lg border-sky-500">
                    <img
                      src="/images/nft/story.png"
                      alt="icons"
                      className="w-14"
                    />
                    <p className="text-base font-medium text-white">
                      Track your mood and write journal
                    </p>
                  </div>
                  <div className="flex items-center p-4 space-x-4 border rounded-lg border-sky-500">
                    <img
                      src="/images/nft/music.png"
                      alt="icons"
                      className="w-14"
                    />
                    <p className="text-base font-medium text-white">
                      Inspirational stories to keep you motivated
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 10 */}
      <div
        id="app"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative items-center px-6 pt-24 mx-auto md:flex 2xl:px-0">
          <div className="w-full py-10 space-y-10 lg:flex lg:items-center lg:space-x-10 lg:space-y-0">
            {/* text  */}
            <div
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="w-full space-y-6 text-center md:text-left "
            >
              <h1 className="text-2xl font-bold text-white xl:text-5xl xl:leading-normal ">
                All-in-one <br />
                wellbeing App
              </h1>
              <p className="text-base lg:text-3xl text-sky-500 ">
                Get unlimeted primium access <br />
                to hapbit app
              </p>
              <div className="flex justify-center pt-4 space-x-6 md:justify-start">
                <a href="https://apps.apple.com/in/app/happbit/id1588284715">
                  {" "}
                  <img
                    src="/images/nft/appstore.png"
                    alt="appstore"
                    className="w-44"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.happbit">
                  <img
                    src="/images/nft/playstore.png"
                    alt="playstore"
                    className="w-44"
                  />
                </a>
              </div>
            </div>
            <div
              className="w-full "
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <div className="grid w-full grid-cols-2 gap-6 py-10 md:gap-10">
                <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
                  <h1 className="flex items-center justify-center text-3xl font-bold text-yellow-500 md:text-5xl">
                    7000{" "}
                    <span class="material-icons-round  text-[2rem]">add</span>
                  </h1>
                  <p className="text-base font-medium text-white">Happy user</p>
                </div>
                <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
                  <h1 className="flex items-center justify-center text-3xl font-bold text-yellow-500 md:text-5xl">
                    27{" "}
                    <span class="material-icons-round  text-[2rem]">add</span>
                  </h1>
                  <p className="text-base font-medium text-white">Experts</p>
                </div>
                <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
                  <h1 className="flex items-center justify-center text-3xl font-bold text-yellow-500 md:text-5xl">
                    4.5{" "}
                    <span class="material-icons-round  text-[2rem]">star</span>
                  </h1>
                  <p className="text-base font-medium text-white">
                    Average user rating
                  </p>
                </div>
                <div className="p-6 py-10 space-y-6 text-center bg-black bg-opacity-20 rounded-xl">
                  <h1 className="text-3xl font-bold text-yellow-500 md:text-5xl">
                    Web3
                    {/* <span class="material-icons-round ml-0 text-[2rem]">
                                        percent
                                        </span> */}
                  </h1>
                  <p className="text-base font-medium text-white">
                    Web3 Ready App
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 11 */}
      <div
        id="team"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container px-6 pt-24 mx-auto space-y-10 xl:px-">
          <div className="flex flex-col items-center justify-center space-y-2">
            <h1 className="py-4 text-3xl font-bold text-white capitalize">
              Meet Our Team
            </h1>
            <div className="w-20 border-b-2 border-yellow-500"></div>
          </div>

          <div className="grid grid-cols-1 gap-8 py-10 xl:grid-cols-5 md:grid-cols-3">
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Ashish.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Dr. Ashish Ambasta
                </h1>
                <p className="font-medium text-gray-500">Happiness Expert</p>
              </div>
            </div>

            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Basant.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Basant Kumar
                </h1>
                <p className="font-medium text-gray-500">Metaverse Expert</p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Shayama.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white line-clamp-1 ">
                  Shyamasree Chakrabarty
                </h1>
                <p className="font-medium text-gray-500">Happiness Expert</p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Vineet.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Vinit Singh{" "}
                </h1>
                <p className="font-medium text-gray-500">
                  {" "}
                  Business Development Head
                </p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Abhinaw.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Abhinaw Kumar
                </h1>
                <p className="font-medium text-gray-500"> Web3 Architect</p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Ankit.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Ankit Choudhary
                </h1>
                <p className="font-medium text-gray-500">
                  Hybrid Mobile Developer
                </p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Aniket.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Aniket Verma
                </h1>
                <p className="font-medium text-gray-500">
                  Full Stack Developer{" "}
                </p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Hari.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Hari Kunwar
                </h1>
                <p className="font-medium text-gray-500">UI/UX Developer</p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Krishna.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">
                  Krishna Deva
                </h1>
                <p className="font-medium text-gray-500">
                  Full Stack Developer
                </p>
              </div>
            </div>
            <div className="p-6 space-y-4 bg-black bg-opacity-20 rounded-xl">
              <img
                src="\images\happybit\Taj.png"
                title="blockchain"
                alt="blockchain"
                className="object-cover w-full rounded-lg"
              />
              <div className="text-center">
                <h1 className="text-lg font-semibold text-white">Taj Khan </h1>
                <p className="font-medium text-gray-500"> AI Expert</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 12 */}
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="bg-gray-800 bg-right bg-no-repeat bg-cover"
        style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}
      >
        <div className="container relative px-6 pt-24 mx-auto space-y-10 2xl:px-0">
          <div className="flex flex-col items-center justify-center text-center">
            <h1 className="py-4 text-3xl font-bold text-white capitalize">
              Frequently Asked Questions
            </h1>
            <div className="w-20 border-b-2 border-yellow-500"></div>
          </div>
          <div className="py-10 space-y-10 lg:flex lg:items-center lg:space-x-10 lg:space-y-0">
            {/* text */}
            <div
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="w-full space-y-4 text-center md:text-left "
            >
              {faqs.map((faq, index) => {
                return (
                  <AccordionItem
                    key={index}
                    active1={active1}
                    handleToggle={handleToggle}
                    faq={faq}
                  />
                );
              })}
            </div>

            {/* img */}
            <div
              className="flex justify-center w-full"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <img
                src="/images/nft/faq.png"
                title="blockchain"
                alt="blockchain"
                className="w-4/6 "
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default withRouter(Home);
