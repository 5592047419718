import Header from '../component/Header';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef, useState } from "react";

import {
    withRouter
} from "react-router-dom";


const faqs = [
    {
        id: 1,
        header: "What is Lorem Ipsum?",
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
    },
    {
        id: 2,
        header: "Where does it come from?",
        text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `
    },
    {
        id: 3,
        header: "Why do we use it?",
        text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`
    },
    {
        id: 4,
        header: "Where can I get some?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
    },
    {
        id: 5,
        header: "Where can I get some?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
    },
    {
        id: 6,
        header: "Where can I get some?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
    }
]



const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active1, faq } = props;
    const { header, id, text } = faq;

    return (
        <div className="rc-accordion-card overflow-hidden bg-black bg-opacity-20  rounded-lg  cursor-pointer">
            <div className='rc-accordion-header flex items-center space-x-4'>
                <div className={`rc-accordion-toggle flex items-center space-x-4 p-6 text-white ${active1 === id ? 'active1' : ''}`} onClick={() => handleToggle(id)}>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                    <h5 className="rc-accordion-title text-white text-lg font-semibold xl:leading-normal">{header}</h5>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse text-base text-white  overflow-hidden ${active1 === id ? 'show' : ''}`} style={
                active1 === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }}>
                <div className="rc-accordion-body py-6">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </div>
    )
}

function Landing(props) {

    const slider = useRef();

    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    };

    const settingstwo = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        arrows: false,
        autoplaySpeed: 0,
        cssEase: "linear",
        pauseOnHover: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }

        ]
    };
    const settingsthree = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: -1,
        autoplay: true,
        speed: 4000,
        arrows: false,
        autoplaySpeed: 0,
        cssEase: "linear",
        pauseOnHover: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: -1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: -1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: -1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: -1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: -1
                }
            }

        ]

    };


    const [active1, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active1 === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }



    return (
        <div className='bg-gray-800 space-y-10'>

            <Header />
            {/* section 1 */}
            <div data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="md:h-[calc(100vh_-_14rem)]    md:flex items-center relative container mx-auto xl:px-0 px-6  ">

                    <div className="flex md:flex-row flex-col-reverse    md:items-center py-10 md:space-x-10 md:space-y-0 space-y-10">

                        {/* text
              .+8+.-section */}
                        <div data-aos="fade-right" data-aos-delay="50"
                            data-aos-duration="1000" className="space-y-6 w-full md:text-left text-center md:pt-0 pt-10 ">
                            <h1 className="text-white xl:text-5xl text-4xl font-bold xl:leading-normal  ">The Happyverse NFT Collection</h1>
                            <p className="lg:text-lg  text-base text-white ">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                            <div className="flex md:justify-start justify-center space-x-4">

                                <button href="/contact-us" class="bg-yellow-500 text-slate-800 px-6 py-3 rounded-full flex items-center text-lg">
                                    <span class="">
                                        Meet Now</span>
                                    <span class="material-symbols-outlined ml-1">
                                        chevron_right
                                    </span>

                                </button>
                            </div>
                        </div>
                        <div className="flex justify-end w-full relative" data-aos="fade-left" data-aos-delay="50"
                            data-aos-duration="1000">
                            <div className='flex items-start'  >
                                <img src="/images/nft/item-1.jpg" title='blockchain' alt="monkeybrown" className='xl:w-60 md:w-56 w-40 rounded-lg  z-10 shadow-xl transform  md:translate-y-20 -translate-y-2 left-0  ' />
                                <img src="/images/nft/item-3.jpg" title='blockchain' alt="blockchain" className='xl:w-60 md:w-56 w-40 rounded-lg  shadow-xl lg:-translate-x-10 md:-translate-x-40 -translate-x-10   -translate-y-20 transform    ' />
                                <img src="/images/nft/item-2.jpg" title='blockchain' alt="blockchain" className='xl:w-56 md:w-40 w-36 rounded-lg  shadow-xl left-0 md:translate-y-14 -translate-y-2 xl:-translate-x-20 lg:-translate-x-40 md:-translate-x-64 -translate-x-20 transform z-20' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* section 2 */}
            <div className='bg-gray-800'>
                <div className='container xl:px-0 px-6 mx-auto grid lg:grid-cols-4 grid-cols-2 gap-10 md:py-20 '>
                    <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                        <h1 className='text-3xl font-bold text-yellow-500'>
                            0,500h ETH
                        </h1>
                        <p className='text-base font-medium text-white'>
                            mint drop #2
                        </p>


                    </div>
                    <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                        <h1 className='text-3xl font-bold text-yellow-500'>
                            0,500h ETH
                        </h1>
                        <p className='text-base font-medium text-white'>
                            mint drop #2
                        </p>

                    </div>
                    <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                        <h1 className='text-3xl font-bold text-yellow-500'>
                            0,500h ETH
                        </h1>
                        <p className='text-base font-medium text-white'>
                            mint drop #2
                        </p>

                    </div>
                    <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                        <h1 className='text-3xl font-bold text-yellow-500'>
                            0,500h ETH
                        </h1>
                        <p className='text-base font-medium text-white'>
                            mint drop #2
                        </p>
                    </div>

                </div>
            </div>

            {/* section 3 */}
            <div id="about" data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="md:h-[calc(100vh_-_14rem)]     relative container md:px-0 px-6 mx-auto space-y-20 ">

                    <div className='flex flex-col items-center justify-center space-y-2'>
                        <h1 className='py-4  text-3xl font-bold text-white capitalize'>Our story</h1>
                        <div className='w-20 border-b-2 border-yellow-500'></div>
                    </div>

                    <div className="flex lg:flex-row flex-col md:items-center py-10 md:space-x-10 md:space-y-0 space-y-10">


                        <div className="flex md:justify-center justify-start items-start w-full" data-aos="fade-left" data-aos-delay="50"
                            data-aos-duration="1000">
                            <img src="/images/nft/item-4.jpg" title='blockchain' alt="blockchain" className='md:w-80 w-60 rounded-lg shadow-xl origin-center -rotate-12 transform md:translate-x-28 translate-x-10 object-cover' />
                            <img src="/images/nft/item-5.jpg" title='blockchain' alt="blockchain" className='md:w-56 w-40 rounded-lg shadow-xl origin-center rotate-12 transform -translate-y-20 -translate-x-8  object-cover' />
                        </div>
                        {/* text */}
                        <div data-aos="fade-right" data-aos-delay="50"
                            data-aos-duration="1000" className="space-y-6 w-full md:text-left text-center ">
                            <h1 className="text-white xl:text-5xl text-2xl font-bold xl:leading-normal ">Welcome to Happyverse.</h1>
                            <p className="lg:text-lg  text-base text-white ">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                            <p className="lg:text-lg  text-base text-white ">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                            <div className="flex md:justify-start justify-center space-x-4">

                                <button href="/contact-us" class="bg-yellow-500 text-slate-800 px-6 py-3 rounded-full flex items-center text-lg">
                                    <span class="">
                                        Join our Discord</span>
                                    <span class="material-symbols-outlined ml-1">
                                        chevron_right
                                    </span>

                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            {/* section 4 */}
            <div id='roadmap' data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className=" relative container mx-auto space-y-10  ">


                    <div className='flex flex-col items-center justify-center space-y-2'>
                        <h1 className='py-4  text-3xl font-bold text-white capitalize'>NFT Drops Road Map</h1>
                        <div className='w-20 border-b-2 border-yellow-500'></div>
                    </div>

                </div>
            </div>


            <div className="flex md:flex-row flex-col-reverse  md:items-center py-20 md:space-x-10 md:space-y-0   container mx-auto lg:px-0 px-6">

                {/* text */}
                <div data-aos="fade-right" data-aos-delay="50"
                    data-aos-duration="1000" className="space-y-6 w-full md:text-left text-center md:mt-0 mt-10 ">
                    <h1 className="text-white xl:text-5xl text-2xl font-bold xl:leading-normal ">Happyverse NFT <br /> Distribution.</h1>

                    <p className="lg:text-lg  text-base text-white ">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                    <div className="flex md:justify-start justify-center space-x-4">

                        <button href="/contact-us" class="bg-yellow-500 text-slate-800 px-6 py-3 rounded-full flex items-center text-lg">
                            <span class="">
                                Join our Discord</span>
                            <span class="material-symbols-outlined ml-1">
                                chevron_right
                            </span>

                        </button>
                    </div>
                </div>

                <div className="flex justify-center w-full" data-aos="fade-left" data-aos-delay="50"
                    data-aos-duration="1000">
                    <img src="/images/nft/item-9.jpg" title='blockchain' alt="blockchain" className='md:h-96 w-full rounded-2xl object-cover' />
                </div>


            </div>

            {/* section 5 */}
            <div id="collection" data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="    relative   space-y-10  ">


                    <div className='flex flex-col items-center justify-center space-y-2'>
                        <h1 className='py-4  text-3xl font-bold text-white capitalize'>Our Collection</h1>
                        <div className='w-20 border-b-2 border-yellow-500'></div>
                    </div>

                    <div className=" py-10 space-y-8 overflow-hidden ">

                        <div className="flex space-y-10">
                            <Slider  {...settingstwo} className="w-full" >
                                <div className='px-4'>
                                    <img src="/images/nft/item-5.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-3.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-6.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-4.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-2.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-1.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-5.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-3.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-6.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-4.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-2.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-1.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-5.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-3.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-6.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-4.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                            </Slider>


                        </div>
                        <div className="flex space-y-10">
                            <Slider  {...settingsthree} className="w-full" >
                                <div className='px-4'>
                                    <img src="/images/nft/item-7.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-8.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-9.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-10.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-11.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-7.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-8.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-9.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-10.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-11.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-7.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-8.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-9.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-10.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-11.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                                <div className='px-4'>
                                    <img src="/images/nft/item-4.jpg" title='blockchain' alt="blockchain" className='rounded-lg' />
                                </div>
                            </Slider>


                        </div>






                    </div>
                </div>
            </div>

            {/* section 6 */}
            <div data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="md:h-[calc(100vh_-_14rem)]    md:flex items-center relative container mx-auto lg:px-0 px-6  ">

                    <div className="flex md:flex-row flex-col-reverse md:items-center py-10 md:space-x-10 md:space-y-0 space-y-10">

                        {/* text  */}
                        <div data-aos="fade-right" data-aos-delay="50"
                            data-aos-duration="1000" className="space-y-6 w-full md:text-left text-center md:mt-0 mt-10">
                            <h1 className="text-white xl:text-5xl text-2xl font-bold xl:leading-normal ">Metaverse Ready</h1>
                            <p className="lg:text-lg  text-base text-white ">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>

                            <p className="lg:text-lg  text-base text-white ">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>


                        </div>
                        <div className="flex justify-center w-full" data-aos="fade-left" data-aos-delay="50"
                            data-aos-duration="1000">
                            <img src="/images/nft/metaverse_ready.svg" title='blockchain' alt="blockchain" className='xl:w-4/6 w-full rounded-lg' />
                        </div>

                    </div>
                </div>
            </div>

            {/* section 7 */}
            <div data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="md:h-[calc(100vh_-_14rem)]     relative container mx-auto  space-y-10  ">



                    <div className='flex flex-col items-center justify-center space-y-2'>
                        <h1 className='py-4  text-3xl font-bold text-white capitalize'>NFT Road map</h1>
                        <div className='w-20 border-b-2 border-yellow-500'></div>
                    </div>


                    <div className=" py-10 space-y-8 overflow-hidden ">
                        <h1>Here is roadmap</h1>


                    </div>
                </div>
            </div>


            {/* section 8 */}
            <div id="holder" data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>
                <div className="md:h-[calc(100vh_-_14rem)]     relative container mx-auto lg:px-0 px-6  md:space-y-20 space-y-10 py-20  ">
                    <div className='flex justify-between items-center'>
                        <div>
                            <h1 className='py-4   md:text-5xl text-3xl font-bold text-white capitalize'>Holder benifits</h1>
                            <p className='text-lg text-white'>The Scort Art Studio a private Collection of NFTs unique difital Collectibles.</p>
                        </div>
                        <div>
                            <div className='flex md:flex-row flex-col md:space-x-6 md:space-y-0 space-y-4'>
                                <span onClick={previous} class="material-symbols-outlined rounded-full  p-2 md:px-2 px-4  bg-black bg-opacity-40 text-white ">
                                    arrow_back
                                </span>
                                <span onClick={next} class="material-symbols-outlined rounded-full  p-2 md:px-2 px-4  bg-blue-500  text-white ">
                                    arrow_forward
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className="flex w-full overflow-hidden text-left">

                        <Slider ref={(c) => (slider.current = c)}  {...settings} className="w-full flex justify-start flex-shrink  gap-8 ">
                            <div className='md:-mx-4 group cursor-pointer '>
                                <div className='md:px-4'>
                                    <div className='border-2 border-blue-500 rounded-2xl p-8 py-10 space-y-6 group-hover:bg-blue-500 text-white   transition duration-200 '>
                                        <h1 className='text-xl font-semibold '>Happyverse NFT(s)</h1>
                                        <p className='text-base  '>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>

                                        <ul className='  text-base  list-disc pl-4 space-y-4'>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='md:-mx-4 group cursor-pointer '>
                                <div className='md:px-4'>
                                    <div className='border-2 border-blue-500 rounded-2xl p-8 py-10 space-y-6 group-hover:bg-blue-500 text-white   transition duration-200 '>
                                        <h1 className='text-xl font-semibold '>Happyverse NFT(s)</h1>
                                        <p className='text-base  '>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>

                                        <ul className='  text-base  list-disc pl-4 space-y-4'>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='md:-mx-4 group cursor-pointer '>
                                <div className='md:px-4'>
                                    <div className='border-2 border-blue-500 rounded-2xl p-8 py-10 space-y-6 group-hover:bg-blue-500 text-white   transition duration-200 '>
                                        <h1 className='text-xl font-semibold '>Happyverse NFT(s)</h1>
                                        <p className='text-base  '>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>

                                        <ul className='  text-base  list-disc pl-4 space-y-4'>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='md:-mx-4 group cursor-pointer '>
                                <div className='md:px-4'>
                                    <div className='border-2 border-blue-500 rounded-2xl p-8 py-10 space-y-6 group-hover:bg-blue-500 text-white   transition duration-200 '>
                                        <h1 className='text-xl font-semibold '>Happyverse NFT(s)</h1>
                                        <p className='text-base  '>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>

                                        <ul className='  text-base  list-disc pl-4 space-y-4'>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                            <li>In publishing and graphic design, Lorem ipsum is a placeholder</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </Slider>

                    </div>
                </div>
            </div>
            {/* section 9 */}
            <div data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="md:h-[calc(100vh_-_14rem)]     relative container mx-auto lg:px-0 px-6 space-y-10  ">

                    <div className='flex flex-col items-center justify-center space-y-2 text-center'>
                        <h1 className='py-4  text-3xl font-bold text-white capitalize'>Happiness and wellbeing</h1>
                        <div className='w-20 border-b-2 border-yellow-500'></div>
                    </div>

                    <div className="md:flex   md:items-center py-10 md:space-x-10 md:space-y-0 space-y-10">

                        {/* img */}
                        <div className="flex justify-end w-full" data-aos="fade-left" data-aos-delay="50"
                            data-aos-duration="1000">
                            <img src="/images/nft/app.png" title='blockchain' alt="blockchain" className='w-5/6 mr-auto' />
                        </div>

                        {/* text */}
                        <div data-aos="fade-right" data-aos-delay="50"
                            data-aos-duration="1000" className="space-y-6 w-full md:text-left text-center ">
                            <h1 className="text-white xl:text-5xl text-2xl font-bold xl:leading-normal ">Companion in your  <br />Happiness Journey.</h1>

                            <p className="lg:text-lg  text-base text-white ">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>



                            <div className="space-y-6">
                                <h1 className='text-blue-500 text-xl tracking-wider uppercase '>feature you will love</h1>
                                <div className='grid md:grid-cols-2 grid-cols-1 gap-6'>
                                    <div className='border border-blue-500 p-4 rounded-lg space-x-4 flex items-center'>
                                        <img src='/images/nft/wellbeing_habits.png' alt='icons' className='w-14' />
                                        <p className='text-white text-base font-medium'>Build Happiness and wellbeing Habits</p>
                                    </div>
                                    <div className='border border-blue-500 p-4 rounded-lg space-x-4 flex items-center'>
                                        <img src='/images/nft/mood_track.png' alt='icons' className='w-14' />
                                        <p className='text-white text-base font-medium'>Build Happiness and wellbeing Habits</p>
                                    </div>
                                    <div className='border border-blue-500 p-4 rounded-lg space-x-4 flex items-center'>
                                        <img src='/images/nft/talk_to_experts.png' alt='icons' className='w-14' />
                                        <p className='text-white text-base font-medium'>Build Happiness and wellbeing Habits</p>
                                    </div>
                                    <div className='border border-blue-500 p-4 rounded-lg space-x-4 flex items-center'>
                                        <img src='/images/nft/story.png' alt='icons' className='w-14' />
                                        <p className='text-white text-base font-medium'>Build Happiness and wellbeing Habits</p>
                                    </div>
                                    <div className='border border-blue-500 p-4 rounded-lg space-x-4 flex items-center'>
                                        <img src='/images/nft/music.png' alt='icons' className='w-14' />
                                        <p className='text-white text-base font-medium'>Build Happiness and wellbeing Habits</p>
                                    </div>

                                </div>


                            </div>
                        </div>



                    </div>
                </div>
            </div>


            {/* section 10 */}
            <div id='app' data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className=" md:flex items-center relative container mx-auto md:px-0 px-6  ">

                    <div className="md:flex  md:items-center py-10 md:space-x-10 md:space-y-0 space-y-10 w-full">

                        {/* text  */}
                        <div data-aos="fade-right" data-aos-delay="50"
                            data-aos-duration="1000" className="space-y-6 w-full md:text-left text-center ">
                            <h1 className="text-white xl:text-5xl text-2xl font-bold xl:leading-normal ">All-in-one <br />wellbeing App</h1>
                            <p className="lg:text-3xl  text-base text-blue-500 ">Get unlimeted primium access <br />to hapbit app</p>
                            <div className='space-x-6 flex pt-4 md:justify-start justify-center'>
                                <img src='/images/nft/appstore.png' alt='appstore' className='w-44' />
                                <img src='/images/nft/playstore.png' alt='playstore' className='w-44' />
                            </div>

                        </div>
                        <div className=" w-full" data-aos="fade-left" data-aos-delay="50"
                            data-aos-duration="1000">
                            <div className=' grid grid-cols-2 md:gap-10 gap-6 md:py-20 py-10 w-full'>
                                <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                                    <h1 className='text-5xl font-bold text-yellow-500'>
                                        7000 +
                                    </h1>
                                    <p className='text-base font-medium text-white'>
                                        Happy user
                                    </p>


                                </div>
                                <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                                    <h1 className='text-5xl font-bold text-yellow-500'>
                                        8 +
                                    </h1>
                                    <p className='text-base font-medium text-white'>
                                        Therapetutics techiniqes
                                    </p>

                                </div>
                                <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                                    <h1 className='text-5xl font-bold text-yellow-500'>
                                        4.9 *
                                    </h1>
                                    <p className='text-base font-medium text-white'>
                                        Average user rating
                                    </p>

                                </div>
                                <div className='bg-black bg-opacity-20 p-6 rounded-xl text-center space-y-6 py-10'>
                                    <h1 className='text-5xl font-bold text-yellow-500'>
                                        35 %
                                    </h1>
                                    <p className='text-base font-medium text-white'>
                                        people have mental helath issues
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* section 11 */}
            <div id="team" data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="  container mx-auto xl:px-0 px-6  space-y-10  ">
                    <div className='flex flex-col items-center justify-center space-y-2'>
                        <h1 className='py-4  text-3xl font-bold text-white capitalize'>Meet Our Team</h1>
                        <div className='w-20 border-b-2 border-yellow-500'></div>
                    </div>

                    <div className=" py-10 grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-10 ">
                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader-8.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>

                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>
                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader-2.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>
                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader-3.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>
                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader-4.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>
                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader-5.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>
                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader-6.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>
                        <div className='bg-black bg-opacity-20 p-6 rounded-xl space-y-4'>
                            <img src="/images/nft/leader-7.jpg" title='blockchain' alt="blockchain" className='rounded-lg  object-cover  w-full' />
                            <div className='text-center'>
                                <h1 className='text-white font-semibold text-lg'>Neal kumar</h1>
                                <p className='text-gray-500 font-medium'>CEO & Founder</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section 12 */}
            <div data-aos="fade-up" data-aos-delay="50"
                data-aos-duration="1000" className="bg-cover bg-no-repeat bg-right bg-gray-800" style={{ backgroundImage: `url("images/img/blockchainbg.webp")` }}>

                <div className="md:h-[calc(100vh_-_14rem)]     relative container xl:px-0 px-6 mx-auto space-y-10  ">

                    <div className='flex flex-col items-center justify-center  text-center'>
                        <h1 className='py-4  text-3xl font-bold text-white capitalize'>Frequently Asked Questions</h1>
                        <div className='w-20 border-b-2 border-yellow-500'></div>
                    </div>
                    <div className="md:flex   md:items-center py-10 md:space-x-10 md:space-y-0 space-y-10">

                        {/* text */}
                        <div data-aos="fade-right" data-aos-delay="50"
                            data-aos-duration="1000" className="space-y-4 w-full md:text-left text-center ">

                            {faqs.map((faq, index) => {
                                return (
                                    <AccordionItem key={index} active1={active1} handleToggle={handleToggle} faq={faq} />
                                )
                            })
                            }


                        </div>

                        {/* img */}
                        <div className="flex justify-center  w-full" data-aos="fade-left" data-aos-delay="50"
                            data-aos-duration="1000">
                            <img src="/images/nft/faq.png" title='blockchain' alt="blockchain" className='w-4/6  ' />
                        </div>





                    </div>
                </div>
            </div>


            <section className='bg-gray-800'>
                <div className='container mx-auto xl:px-0 px-6 md:py-20 py-10 '>
                    <div className=' bg-blue-500 rounded-2xl p-6  py-10 text-center flex flex-col items-center space-y-8'>
                        <img src='/images/nft/discord.png' alt='monkey' />
                        <h1 className='text-5xl  font-bold text-white'>Join our discord community</h1>
                        <button href="/contact-us" className="bg-yellow-500 text-slate-800 px-10 py-3 rounded-full flex items-center text-lg">
                            <span className='font-medium '>Join  Discord Now</span><span className="material-symbols-outlined ml-1">chevron_right</span></button>

                    </div>
                    {/* icons */}
                    <div className='flex space-x-6 justify-center py-16'>
                        <div className='p-3 bg-white bg-opacity-20 text-white rounded-full'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-meta" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8.217 5.243C9.145 3.988 10.171 3 11.483 3 13.96 3 16 6.153 16.001 9.907c0 2.29-.986 3.725-2.757 3.725-1.543 0-2.395-.866-3.924-3.424l-.667-1.123-.118-.197a54.944 54.944 0 0 0-.53-.877l-1.178 2.08c-1.673 2.925-2.615 3.541-3.923 3.541C1.086 13.632 0 12.217 0 9.973 0 6.388 1.995 3 4.598 3c.319 0 .625.039.924.122.31.086.611.22.913.407.577.359 1.154.915 1.782 1.714Zm1.516 2.224c-.252-.41-.494-.787-.727-1.133L9 6.326c.845-1.305 1.543-1.954 2.372-1.954 1.723 0 3.102 2.537 3.102 5.653 0 1.188-.39 1.877-1.195 1.877-.773 0-1.142-.51-2.61-2.87l-.937-1.565ZM4.846 4.756c.725.1 1.385.634 2.34 2.001A212.13 212.13 0 0 0 5.551 9.3c-1.357 2.126-1.826 2.603-2.581 2.603-.777 0-1.24-.682-1.24-1.9 0-2.602 1.298-5.264 2.846-5.264.091 0 .181.006.27.018Z" />
                            </svg>
                        </div>
                        <div className='p-3 bg-white bg-opacity-20 text-white rounded-full'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                            </svg>
                        </div>
                        <div className='p-3 bg-white bg-opacity-20 text-white rounded-full'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-instagram" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>
                        </div>
                        <div className='p-3 bg-white bg-opacity-20 text-white rounded-full'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="26" height="26" fill="currentColor" class="bi bi-instagram"> <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>
                        </div>

                    </div>
                    {/* copyright */}
                    <div className='text-center text-white text-base font-medium capitalize '>
                        <p>copyright &#169; 2023 <b className='text-yellow-500 font-medium'>HappBit</b> All Right Reserved.</p>
                    </div>

                    {/* links */}
                    <div className='text-center text-white text-base font-medium capitalize flex justify-center space-x-6 py-10 '>
                        <a href='/'>Security</a> <a href='/'>Terms</a> <a href='/'>Privacy</a>
                    </div>

                </div>

            </section>
        </div>

    );
}

export default withRouter(Landing);