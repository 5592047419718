import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {

  switch (action.type) {
    
    case userConstants.DISABLE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateEmployeeSuccess: false,
      };
    case userConstants.DISABLE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        // disbaleEmployeeItems: action.users.disbaleEmployee
      };
    case userConstants.DISABLE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: action.error
      };
      
    case userConstants.GET_CLIENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateEmployeeSuccess: false,
      };
    case userConstants.GET_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        items: action.users.getClientProfile,
        profile: action.users.getClientProfile.profile
      };
    case userConstants.GET_CLIENT_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        filesDetails: action.uploadImage.filesDetails,
      };
    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };

    case userConstants.GET_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateEmployeeSuccess: false,
      };
    case userConstants.GET_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        companyProfile: action.users.companyProfile,
        // total: action.users.empolyeeListData.length
      };
    case userConstants.GET_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.COMPANY_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateEmployeeSuccess: false,
      };
    case userConstants.COMPANY_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        items: action.users.empolyeeListData.list,
        total: action.users.empolyeeListData.length
        
      };
    case userConstants.COMPANY_EMPLOYEE_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        items: action.users.listOfRestaurant.list,
        total: action.users.listOfRestaurant.total
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.TX_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.TX_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        employeeList: action.users.empolyeeListData.list,
        employeeTotal: action.users.empolyeeListData.total,
      };
    case userConstants.TX_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GETALL_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.users.listOfNotification.list,
        listOfNotificationtotal: action.users.listOfNotification.total
      };
    case userConstants.GETALL_USER_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.UPDATE_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case userConstants.UPDATE_USER_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.users.restaurantDetails
      };
    case userConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.ADD_RESTAURANT_USER_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_RESTAURANT_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.ADD_RESTAURANT_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
    case userConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case userConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case userConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case userConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case userConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.users.statics
      };
    case userConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // NOW Start New API Interigation


    case userConstants.DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        // updateEmployeeSuccess: true,
        deleteEmployee: action.user.deleteEmployee
      };
    case userConstants.DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        updateEmployeeSuccess: true,
        updateEmployee: action.user.updateEmployee
      };
    case userConstants.UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.SAVE_EMPLYEE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_EMPLYEE_COMPANY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        saveEmplyeeCompany: action.user.saveEmplyeeCompany
      };
    case userConstants.SAVE_EMPLYEE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.UPADTE_CLIENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPADTE_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
      };
    case userConstants.UPADTE_CLIENT_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.CREATE_NEWCOMPANY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_NEWCOMPANY_SUCCESS:
      return {
        ...state,
        addnewData:true,
        newCompanyDetails: action.user.userinfo.data
      };
    case userConstants.RESET_NEWCOMPANY_SUCCESS:
      return {
        ...state,
        addnewData:false
      };
    case userConstants.CREATE_NEWCOMPANY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      // console("action.useraction.useraction.user  ",action.user);
      return {
        ...state,
        newCompanyDetails: action.user.user.userinfo,
        loggedInEmail: action.user.data,
        // newCompanyDetails: action.user.userinfo,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.USERS_VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USERS_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        userinfo: true,
      };
    case userConstants.USERS_VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}