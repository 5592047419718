import React, { useState, useEffect } from "react";
import Header from '../component/Header';
import Footer from '../component/Footer';
// import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";


function About() {
  const [showAlert, setShowAlert] = useState(true);

  // const [showMe, setShowMe] = useState(true);

  function toggle() {
    setShowAlert(!showAlert);
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "ContactUs";
    setShowAlert(false)


  }, [])


  let list = [
    {
      "id": 1,
      "name": "Dr. Ashish Ambasta",
      "designation": "Founder/CEO",
      "image": "/images/dr._ashish_ambasta-1.png",
      "content": "After working in different firms including Indian conglomerates & World-renowned consulting firms he has decided to devote his life to improving happiness around the world through Happy+ It’s a technology-enabled happiness habit formation organisation that intends to change the way people look at happiness. He is ably backed by a team of talented people who are experts in their respective spheres. He is also the founder of SEEtalks which tells the untold stories of changemakers to inspire the next generation to do good. Ashish is also on board with Love Heals Cancer along with Kindness Unlimited and is trying to make the world a happy place."
    },
    {
      "id": 2,
      "name": "Basant Kumar",
      "designation": "Co-Founder/CTO",
      "image": "/images/basantsir.jpg",
      "content": "Basant Kumar is the product head in HappyPlus. He is responsible for product strategy, product delivery and product roadmap with a singular focus on leveraging technology for “Happiness bit by bit “. He has extensive experience of 20+ years and worked as CTO and chief product officer of HONO HR – An Employee Engagement & HCM Suite company. He was also co-founder of DataMineria Technologies, which focused on election analytics in India. He has served TCS, Fidelity, and HeadStrong (Genpact company) in several roles. Basant has B.Tech degree from IIT Kharagpur."
    },
    {
      "id": 3,
      "name": "Abhinaw Kumar",
      "designation": "Web3 Architect",
      "image": "/images/abhinav.png",
      "content": "Web 3 architect. The tech ninza, who can solve any problem with ease. Always there when you need and proactive with solutions."
    }
  ]



  return (
    <div>
      <Header />
      <div className="py-20  2xl:w-4/6 xl:w-5/6 xl:px-0 px-6  mx-auto space-y-20 ">

        <div className="space-y-6" >
          <h1 className="text-4xl font-semibold text-center" >Meet Our Leadership</h1>
          <div className="border-b-4 border-pink-500 w-40 mx-auto" ></div>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  xl:gap-16 gap-10 whitespace-nowrap " >

          {list && list.length > 0 ?
            list.map((item) =>
              <div className="text-center   " >
                <img alt="img" src={item.image} className="w-full" />
                <div>
                  <h1 className="text-lg font-semibold" >{item.name}</h1>
                  <p>{item.designation}</p>

                  <button onClick={() => setShowAlert(item.id)} className="px-6 text-base text-white py-1.5 bg-gray-500 mt-4" >Know more</button>
                </div>




                {showAlert === item.id ?
                  <div className="fixed top-0 right-0 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center z-40" >
                    <div className="bg-white 2xl:w-5/12 xl:w-5/12 lg:w-6/12 md:w-8/12 w-5/6 rounded-lg " >
                      <div className="p-4 flex items-center justify-between border-b" >
                        <h1 className="text-xl font-semibold capitalize   " >{item.name}</h1>

                        <svg onClick={toggle} className="hover:bg-gray-100 rounded-full p-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </div>

                      <div className="p-4" >
                        <p className="whitespace-normal text-left " >
                          {item.content}
                        </p>
                      </div>

                    </div>
                  </div>
                  : null}




              </div>
            ) : null}



        </div>


      </div>
      <Footer />



    </div>

  );
}

export default withRouter(About);
