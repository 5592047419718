import React from 'react';
// import { useHistory } from "react-router-dom";
//import ReactDOM from 'react-dom';


const Footer = () => {
  // let history = useHistory()
  return (
 
      <section className="bg-gray-800">
        <div className="container px-6 py-20 mx-auto xl:px- ">
          {/* <div className="flex flex-col items-center p-6 py-10 space-y-8 text-center bg-sky-500 rounded-2xl">
            <img src="/images/nft/discord.png" alt="monkey" />
            <h1 className="text-2xl font-bold text-white xl:text-5xl lg:text-4xl md:text-3xl">
              Join our discord community
            </h1>
            <button
              href="/contact-us"
              className="flex items-center px-10 py-3 text-lg bg-yellow-500 rounded-full text-slate-800"
            >
              <a className="font-medium" href="https://discord.com/invite/HhkMfq24" target="_blank">Join Discord Now</a>
              <span className="ml-1 material-symbols-outlined">
                chevron_right
              </span>
            </button>
          </div> */}
          {/* icons */}
          <div className="flex justify-center py-16 space-x-6">
            <a
              href="https://www.facebook.com/happbitapp"
              target="_blank"
              className="p-3 text-white transition duration-150 bg-white rounded-full cursor-pointer bg-opacity-20 hover:bg-opacity-40"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-meta"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.217 5.243C9.145 3.988 10.171 3 11.483 3 13.96 3 16 6.153 16.001 9.907c0 2.29-.986 3.725-2.757 3.725-1.543 0-2.395-.866-3.924-3.424l-.667-1.123-.118-.197a54.944 54.944 0 0 0-.53-.877l-1.178 2.08c-1.673 2.925-2.615 3.541-3.923 3.541C1.086 13.632 0 12.217 0 9.973 0 6.388 1.995 3 4.598 3c.319 0 .625.039.924.122.31.086.611.22.913.407.577.359 1.154.915 1.782 1.714Zm1.516 2.224c-.252-.41-.494-.787-.727-1.133L9 6.326c.845-1.305 1.543-1.954 2.372-1.954 1.723 0 3.102 2.537 3.102 5.653 0 1.188-.39 1.877-1.195 1.877-.773 0-1.142-.51-2.61-2.87l-.937-1.565ZM4.846 4.756c.725.1 1.385.634 2.34 2.001A212.13 212.13 0 0 0 5.551 9.3c-1.357 2.126-1.826 2.603-2.581 2.603-.777 0-1.24-.682-1.24-1.9 0-2.602 1.298-5.264 2.846-5.264.091 0 .181.006.27.018Z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/happbitapp/?hl=en"
              target="_blank"
              className="p-3 text-white transition duration-150 bg-white rounded-full cursor-pointer bg-opacity-20 hover:bg-opacity-40"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-instagram"
                viewBox="0 0 16 16"
              >
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
              </svg>
            </a>

            <a
              href="https://twitter.com/HappbitApp"
              target="_blank"
              className="p-3 text-white transition duration-150 bg-white rounded-full cursor-pointer bg-opacity-20 hover:bg-opacity-40"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-instagram"
              >
                {" "}
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </div>
          {/* copyright */}
          <div className="text-base font-medium text-center text-white capitalize ">
            <p>
              copyright &#169; 2023{" "}
              <b className="font-medium text-yellow-500">HappBit</b> All Right
              Reserved.
            </p>
          </div>

          {/* links */}
          <div className="flex flex-wrap justify-center gap-6 py-10 text-base font-normal text-center capitalize text-white/80 ">
            <a href="/#about">About</a>
            <a href="/#holder">Holder Benifits</a>
            <a href="/#collection">NFTs Collection</a>{" "}
            <a href="https://happbit.com/PrivacyPolicy">Privacy Policy</a>
          </div>
        </div>
      </section>
  )
};
export default Footer;