
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const userService = {
    userlogin,
    logout,
    uploadImage,
    getAllUser,
    createExpertForm,
    validateOtpNewCompany,
    getCompanyEmployeeList,
    getCompanyProfile,
    saveEmplyeeCompany,
    updateEmployee,
    deleteEmployee,
    getClientProfile,
    updateClientProfile,
    disbaleEmployee
};

function getCompanyProfile(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCompanyProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                companyProfile: data.data
            }
            // console.log("i am in service getCompanyProfile:::", userObj);
            return userObj;
        });
}

function getClientProfile(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getClientProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getClientProfile: data.data
            }
            // console.log("i am in service getClientProfile:::", userObj);
            return userObj;
        });
}

function getCompanyEmployeeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCompanyEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                empolyeeListData: data.data
            }
            console.log("i am in service getCompanyEmployeeList:::", userObj);
            return userObj;
        });
}


function deleteEmployee(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization

    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // console.log("deleteEmployee_deleteEmployee:::HEADER:::", requestOptions);
    return fetch(CONST.BACKEND_URL + `/deleteEmployee`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                deleteEmployee: user.data
            }
            return userObj;
        });
}

function updateEmployee(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization

    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // console.log("updateEmployee_updateEmployee:::HEADER:::", requestOptions);
    return fetch(CONST.BACKEND_URL + `/updateEmployee`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                updateEmployee: user.data
            }
            return userObj;
        });
}

function disbaleEmployee(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization

    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // console.log("updateEmployee_updateEmployee:::HEADER:::", requestOptions);
    return fetch(CONST.BACKEND_URL + `/disbaleEmployee`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                disbaleEmployee: user.data
            }

            console.log("I am in service disbaleEmployee:::", requestOptions);

            return userObj;
        });
}

function saveEmplyeeCompany(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization

    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // console.log("saveEmplyeeCompany_saveEmplyeeCompany:::HEADER:::", requestOptions);
    return fetch(CONST.BACKEND_URL + `/saveEmplyeeCompany`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                saveEmplyeeCompany: user.data
            }
            return userObj;
        });
}

function updateClientProfile(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization

    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // console.log("updateClientProfile_updateClientProfile:::HEADER:::", requestOptions);
    return fetch(CONST.BACKEND_URL + `/updateClientProfile`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                updateClientProfile: user.data
            }
            return userObj;
        });
}

function createExpertForm(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createExpertForm`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            return userObj;
        });
}

function validateOtpNewCompany(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/validateOtp`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            if (user.data) {
                localStorage.setItem('user', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function logout() {
    localStorage.removeItem('adminuser');
}

function userlogin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/userlogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getAllUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                listOfRestaurant: data.data
            }
            console.log();

            return userObj;
        });
}

function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            // console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

