import React,{useEffect}  from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from 'react-router-dom'; 

export default function Header({ fixed }) {
  const history = useHistory();
  const [navbarOpen, setNavbarOpen] = React.useState(false); 
  const [active, setactive] = React.useState(0); 
  useEffect(() => {
    window.scrollTo(0, 0)
    // document.title = "Contact us Information"
  }, [])

  return (

    <nav className="flex flex-wrap items-center justify-between xl:px-2 lg:px-0 px-2 py-3 navbar-expand-lg bg-gray-800 border-b border-white/10 sticky top-0 z-40 ">
      <div className="container px-2 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <span href className="py-2 px-4  inline-flex items-center" onClick={() => history.push('/')} >
            <img src="/images/happbit_logo1 (1).svg" className="w-3/5 lg:w-32" alt="HappyPlus-logo" />
          </span>
          <button
            className="text-gray-400 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center" +
            (navbarOpen ? " flex" : " hidden")
          }
          id="example-navbar-danger"
        >

          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto capitalize">
            <li className="nav-item  p-2">
              <span className="lg:inline-flex lg:w-auto w-full font-medium px-3 py-2 rounded text-gray-800 items-center justify-center hover:text-yellow-500">
                <a href="/#about" onClick={()=> setactive(active === 1 ? 0 : 1)}  className={active === 1 ? "text-yellow-500" : "text-white/80" } >About</a>
              </span>
            </li>
            <li className="nav-item  p-2">
              <span className="lg:inline-flex lg:w-auto w-full font-medium px-3 py-2 rounded text-white/60 items-center justify-center hover:text-yellow-500">
                <a href="/#collection"  onClick={()=> {setactive(active === 2 ? 0 : 2); setNavbarOpen(!navbarOpen)}  }  className={active === 2 ? "text-yellow-500" : "text-white/80" }>NFTs Collections</a>
              </span>
            </li>


            <li className="nav-item  p-2">
              <span className="lg:inline-flex lg:w-auto w-full font-medium px-3 py-2 rounded text-white/60 items-center justify-center hover:text-yellow-500">
                <a href="/#roadmap" onClick={()=> {setactive(active === 3 ? 0 : 3); setNavbarOpen(!navbarOpen)}  }  className={active === 3 ? "text-yellow-500" : "text-white/80" }>Roadmap</a>
              </span>
            </li>


            <li className="nav-item  p-2">
              <span className="lg:inline-flex lg:w-auto w-full font-medium px-3 py-2 rounded text-white/60 items-center justify-center hover:text-yellow-500">
                <a href="/#app" onClick={()=> {setactive(active === 4 ? 0 : 4); setNavbarOpen(!navbarOpen)}  }  className={active === 4 ? "text-yellow-500" : "text-white/80" }>App</a>
              </span>
            </li>

            <li className="nav-item  p-2">
              <span className="lg:inline-flex lg:w-auto w-full font-medium px-3 py-2 rounded text-white/60 items-center justify-center hover:text-yellow-500">
                <a href="/#holder" onClick={()=> {setactive(active === 5 ? 0 : 5); setNavbarOpen(!navbarOpen)}  }  className={active === 5 ? "text-yellow-500" : "text-white/80" }>Holder benifits</a>
              </span>
            </li>
            <li className="nav-item  p-2">
              <span className="lg:inline-flex lg:w-auto w-full font-medium px-3 py-2 rounded text-white/60 items-center justify-center hover:text-yellow-500">
                <a href="/#team" onClick={()=> {setactive(active === 6 ? 0 : 6); setNavbarOpen(!navbarOpen)}  }  className={active === 6 ? "text-yellow-500" : "text-white/80" }>Team</a>
              </span>
            </li>

            <li className="nav-item relative  p-2 group">
              <span  className="lg:flex lg:w-auto w-full font-medium px-3 py-2 rounded text-white/80 flex items-center justify-start hover:text-yellow-500">
                Discover 
                <span className="material-symbols-outlined font-medium"> expand_more  </span>
              </span>
              {/* dropdown */}
              <div className="bg-white rounded-lg absolute shadow-lg border top-14  left-0 overflow-hidden group-hover:block hidden transition-opacity duration-700 delay-200"   >
                <NavLink exact to="/joinusasexpert" activeClassName="active1111" className="px-3 cursor-pointer whitespace-nowrap block hover:bg-slate-100 transition duration-150 p-2 text-base ">Join Us as Expert</NavLink>
                {/* <NavLink exact to="/about" activeClassName="active1111" className="px-3 cursor-pointer whitespace-nowrap block hover:bg-slate-100 transition duration-150 p-2 text-base ">About us</NavLink> */}
                {/* <a   href="https://blog.happbit.com/" activeClassName="active1111" className="px-3 cursor-pointer whitespace-nowrap block hover:bg-slate-100 transition duration-150 p-2 text-base ">Our blog</a> */}
                <NavLink exact to="/ContactUs" activeClassName="active1111" className="px-3 cursor-pointer whitespace-nowrap block hover:bg-slate-100 transition duration-150 p-2 text-base ">Contact Us</NavLink>
              </div>
            </li>

            <li className="nav-item  p-2">
              <a  href="https://app.happbit.com/ " className="bg-yellow-500 lg:inline-flex lg:w-auto w-full font-medium px-6 py-2 rounded-full text-slate-800 items-center justify-center hover:text-white  ">
                Mint Now
              </a>
            </li>
          </ul>

         
        </div>
      </div>
    </nav>

  );
}






