 
import React from "react";

import {
    withRouter
} from "react-router-dom";

const nfts = [
    {
        id: 1,
        img: '/images/nft/item-9.jpg',
        title: "Thanks to your close friend",
        rate: `26.6 ETH`
    },
    {
        id: 2,
        img: '/images/nft/item-1.jpg',
        title: "Thanks to your close friend",
        rate: `35.8 ETH`
    },
    {
        id: 3,
        img: '/images/nft/item-2.jpg',
        title: "Thanks to your close friend",
        rate: `26.6 ETH`
    },
    {
        id: 4,
        img: '/images/nft/item-3.jpg',
        title: "Thanks to your close friend",
        rate: `56.8 ETH`
    },
    {
        id: 5,
        img: '/images/nft/item-4.jpg',
        title: "Thanks to your close friend",
        rate: `77.6 ETH`
    },
    {
        id: 6,
        img: '/images/nft/item-5.jpg',
        title: "Thanks to your close friend",
        rate: `58.1 ETH`
    },
    {
        id: 7,
        img: '/images/nft/item-6.jpg',
        title: "Thanks to your close friend",
        rate: `23.6 ETH`
    },
    {
        id: 8,
        img: '/images/nft/item-7.jpg',
        title: "Thanks to your close friend",
        rate: `56.6 ETH`
    },
    {
        id: 9,
        img: '/images/nft/item-8.jpg',
        title: "Thanks to your close friend",
        rate: `45.6 ETH`
    },
    {
        id: 10,
        img: '/images/nft/item-9.jpg',
        title: "Thanks to your close friend",
        rate: `26.6 ETH`
    },
    {
        id: 11,
        img: '/images/nft/item-1.jpg',
        title: "Thanks to your close friend",
        rate: `62.6 ETH`
    },

    {
        id: 12,
        img: '/images/nft/item-2.jpg',
        title: "The old man hangging ",
        rate: `20.4 ETH`
    },



]

function Staked(props) {

    return (
        <div className='grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1 2xl:gap-16 xl:gap-10 gap-6'>
        {
            nfts.map((elem) => {
                const { title, img, id, rate } = elem;
                return (
                    <div key={id} className='bg-black bg-opacity-20  rounded-xl overflow-hidden '>
                        <img src={img} title='blockchain' alt="blockchain" className='  object-cover  w-full' />
                        <div className='text-left p-4'>
                            <h1 className='text-white font-medium text-lg'>{title}</h1>
                            <p className='text-gray-500 font-medium'>{rate}</p>
                            <div className='flex  pt-2'> 
                                <button className='p-3 bg-sky-500 w-full rounded-md font-medium text-white hover:bg-sky-600 transition duration-150  '>Withdraw</button>
                            </div>
                        </div>
                    </div>

                )
            })
        }

    </div>

    );
}

export default withRouter(Staked);