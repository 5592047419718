import React from "react";
import Header from '../component/Header';
import Footer from '../component/Footer';
import { useHistory } from "react-router-dom";

import {
  withRouter
} from "react-router-dom";


function JoinUsasExpert(props) {
  const history = useHistory();


  return (

    <>
      <div>
        <Header />
        <div>
          <div className="bg-blue-500 md:pt-16 py-10 pb-60  ">
            <div className="lg:w-5/6 w-11/12 mx-auto flex lg:flex-row flex-col lg:space-x-8 md:space-x-6 lg:space-y-0 space-y-6 items-center">
              <div className="text-white space-y-4 w-full">
                <h1 className="capitalize md:text-3xl text-xl font-semibold">Join us as expert</h1>
                <p className="md:text-base text-sm"> ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.</p>
                <p className="md:text-base text-sm"> Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat. Duis aute irure dolor esse
                  cillum dolore eu fugiat nulla pariatur. </p>
              </div>
              <div className="w-full">
                <img src="/images/expert.png" alt="expert.png" className="w-9/12 md:ml-auto mx-auto" />
              </div>
            </div>
          </div>
         
          <div className=" pb-20">
            <div className="lg:w-5/6 w-11/12 mx-auto  bg-white rounded-lg shadow-xl md:p-10 p-4 -mt-56 ">
          
              <div className="flex w-full md:space-x-10">

              <div className="capitalize bg-green-100 w-full text-center flex justify-center  items-center md:p-4 p-2 space-x-2 rounded cursor-pointer">
                <span className="bg-green-500 md:w-12 md:h-12 w-10 h-10 flex items-center justify-center text-sm rounded-full text-white md:text-base">Done</span>
                <p className="text-blue-500 font-medium md:text-base text-sm"> personal details</p>
              </div>

              
                <div className="capitalize bg-blue-100 w-full text-center flex justify-center items-center  md:p-4 p-2 space-x-2 rounded cursor-pointer">
                  <span className="bg-blue-500 md:w-8 md:h-8 w-6 h-6 flex items-center justify-center text-sm rounded-full text-white md:text-base">2</span>
                  <p className="text-blue-500 font-medium md:text-base text-sm"> Additional details</p>
                </div>
              </div>
              <div className="py-6">
                <h1 className="capitalize text-xl text-blue-500 font-medium">Step 2: enter experince and education</h1>
             
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-4 py-6">
                  <div className="space-y-2">
                
                    <div className="space-y-2">
                      <label className="text-sm text-gray-500 capitalize ">specilization</label><br />
                      <div className="flex space-x-6">
         
                        <select class="border border-gray-300 w-full focus:outline-none p-2 rounded-lg bg-white" placeholder="Select specilization">
                          <option>Happiness coach</option>
                          <option>Life Coach</option>
                          <option>Lecturer</option>
                          <option>Psychologist</option>
                          <option>Clinical Psychologist</option>
                          <option>Mental Health Professional</option>
                          <option>Counselling Psychologist</option>
                          <option>Handwriting Coach</option>
                          <option>Diet Therapist & Lifestyle Coach</option>
                          <option>Gratitude-Happiness Coach</option>
                          <option>Facilitator & Life Coach</option>
                        </select>
                        <input type="text" name className="border border-gray-300 w-full focus:outline-none p-2 rounded-lg" placeholder="Enter other specilization" />
                      </div>
                    </div>
                
                    <div className="space-y-2">
                    

                      <label className="text-sm text-gray-500 capitalize ">working experince</label><br />
                      <select class="w-full hover:bg-gray-50 flex items-center text-sm  cursor-pointer p-2.5 justify-between bg-white border border-gray-300 rounded-lg">
                        <option>1+ years</option>
                        <option>2+ years</option>
                        <option>3+ years</option>
                        <option>4+ years</option>
                        <option>5+ years</option>
                        <option>6+ years</option>
                      </select>
                    
                    </div>
               
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm text-gray-500 capitalize ">please tel us somthing about your self</label><br />
                    <textarea rows={5} placeholder="wirte here.." name className="border border-gray-300 w-full focus:outline-none p-2 rounded-lg" defaultValue={""} />
                  </div>
                </div>
              </div>
              <div className="flex justify-end ">
                <button onClick={() => history.push('/joinusasexpert')} className="w-40 capitalize rounded-md hover:bg-blue-500 text-gray-500 mr-2 hover:text-white">previous</button>
                <button className="bg-blue-500 capitalize text-white py-2 w-40 rounded-md">submit</button></div>
            </div>
          </div>
        </div>


        <Footer />
      </div>

    </>

  );
}

export default withRouter(JoinUsasExpert);