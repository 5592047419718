import React, { Component } from "react";
import Header from "../component/Header"; 
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { userActions } from "../../src/_actions";
import Footer from "../component/Footer";
class JoinUsasExpert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isLoading: true,
      expertModal: false,
      specilization1: null,
      experience: null,
      fieldsUpdateProfile: {},
      errorsUpdateProfile: {},
      stateName: {},
    };
  }

  options = [
    { label: "Hindi", value: "Hindi" },
    { label: "English", value: "English" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Bengali", value: "Bengali" },
    { label: "Marathi", value: "Marathi" },
    { label: "Telugu", value: "Telugu" },
    { label: "Tamil", value: "Tamil" },
    { label: "Urdu", value: "Urdu" },
    { label: "Kannada", value: "Kannada" },
    { label: "Odia", value: "Odia" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Assamese", value: "Assamese" },
  ];

  handleSelectedChanged = (selected) => {
    console.log("selectedselectedselected  ", selected);
    this.setState({ selected });
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 5000);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addnewData) {
      console.log(
        "============================================================================="
      );
      return {
        ...nextProps,
        expertModal: false,
        fieldsUpdateProfile: {},
        errorsUpdateProfile: {},
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  onClickMenu = (url) => {
    console.log("hiiiiiiii");
    this.props.history.push(url);
  };

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUpdateProfile = this.state.fieldsUpdateProfile;
    let errorsUpdateProfile = this.state.errorsUpdateProfile;
    fieldsUpdateProfile[name] = value;
    errorsUpdateProfile[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateProfile, errorsUpdateProfile });
  };

  inputChangeState = (event) => {
    this.setState({ stateName: event.target.value });
  };
  inputChangeSpecilization1 = (event) => {
    this.setState({ specilization1: event.target.value });
  };
  inputChangeExperience = (event) => {
    this.setState({ experience: event.target.value });
  };

  inputChangeExpertModal = () => {
    if (this.handleValidationNextForm()) {
      this.setState({ expertModal: true });
    }
  };

  // inputChangeExpertModal = () => {

  //   this.setState({ expertModal: true });

  // }

  inputChangeExpertPreviousModal = () => {
    this.setState({ expertModal: false });
  };

  createExpertForm = (e) => {
    // e.preventDefault();
    if (this.handleValidationProfile()) {
      let selected = this.state.selected.map((e) => e.label).join(",");

      let data = {
        fullName: this.state.fieldsUpdateProfile.fullName,
        language: selected,
        email: this.state.fieldsUpdateProfile.email,
        state: this.state.stateName,
        phone: this.state.fieldsUpdateProfile.phone,
        city: this.state.fieldsUpdateProfile.city,
        gender: "Male",
        specilization1: this.state.specilization1,
        specilization2: this.state.fieldsUpdateProfile.specilization2,
        experience: parseInt(this.state.experience),
        remark: this.state.fieldsUpdateProfile.remark,
      };

      let reqData = {
        keyWord: "",
        pageNo: 1,
        size: 10,
      };

      console.log("datadatadata::", data);

      this.props.dispatch(userActions.createExpertForm(data, reqData));
    }
  };

  handleValidationNextForm = () => {
    let fieldsUpdateProfile = this.state.fieldsUpdateProfile;
    let errorsUpdateProfile = {};
    let formIsValid = true;

    //fullName
    if (!fieldsUpdateProfile["fullName"]) {
      formIsValid = false;
      errorsUpdateProfile["fullName"] = "Please enter full fullName!";
    }

    //email
    if (!fieldsUpdateProfile["email"] || fieldsUpdateProfile["email"] === "") {
      formIsValid = false;
      errorsUpdateProfile["email"] = "Please enter email!";
    }

    console.log(
      "errorsUpdateProfileerrorsUpdateProfileerrorsUpdateProfileerrorsUpdateProfile--------",
      errorsUpdateProfile
    );

    this.setState({ errorsUpdateProfile: errorsUpdateProfile });
    return formIsValid;
  };

  handleValidationProfile = () => {
    let fieldsUpdateProfile = this.state.fieldsUpdateProfile;
    let errorsUpdateProfile = {};
    let formIsValid = true;

    //fullName
    if (!fieldsUpdateProfile["fullName"]) {
      formIsValid = false;
      errorsUpdateProfile["fullName"] = "Please enter full fullName!";
    }

    //email
    if (!fieldsUpdateProfile["email"] || fieldsUpdateProfile["email"] === "") {
      formIsValid = false;
      errorsUpdateProfile["email"] = "Please enter email!";
    }

    console.log(
      "errorsUpdateProfileerrorsUpdateProfileerrorsUpdateProfileerrorsUpdateProfile--------",
      errorsUpdateProfile
    );

    this.setState({ errorsUpdateProfile: errorsUpdateProfile });
    return formIsValid;
  };

  render() {
    const { selected } = this.state;

    return (
      <>
        <div>
          <Header />
          <div className="bg-gray-800 md:pt-16 py-10 pb-20  ">
            <div className=" container mx-auto xl:px-0 px-6 flex lg:flex-row flex-col lg:space-x-8 md:space-x-6 lg:space-y-0 space-y-6 items-center">
              <div className="text-white space-y-4 w-full md:text-left text-center">
                <h1 className="capitalize md:text-4xl text-3xl font-semibold">
                  Join us as expert
                </h1>
                <p className="md:text-xl text-base">
                  Please join the journey of Hope, Positivity & Happiness and
                  help in empowering individual to take ownership of their
                  wellbeing for creating a happier world, enroll yourself as an
                  expert with us.
                </p>
                {/* <p className="md:text-base text-sm"> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor esse cillum dolore eu fugiat nulla pariatur. </p> */}
              </div>
              <div className="w-full">
                <img
                  src="/images/expert.png"
                  alt="expert.png"
                  className="w-9/12 md:ml-auto mx-auto"
                />
              </div>
            </div>
          </div>
          {/* form box  */}
          <div className="space-y-10 bg-gray-800">
            {/* this.state.expertModal  */}
            {this.state.expertModal ? (
              <div className=" ">
                <div className="lg:w-5/6 w-11/12 mx-auto  bg-white/10 rounded-lg shadow-xl md:p-10 p-4  ">
                  <div className="flex md:flex-row flex-col w-full md:gap-10 gap-6">
                    <div className="capitalize w-full text-center flex justify-center  items-center md:p-4 p-2 space-x-2 rounded cursor-pointer bg-white/10">
                      <span
                        className="material-icons-outlined bg-green-400 rounded-full text-white p-1.5"
                        style={{ fontSize: "1rem" }}
                      >
                        done
                      </span>
                      <p className="text-green-400 font-medium md:text-base text-sm">
                        personal details
                      </p>
                    </div>
                    <div className="capitalize bg-yellow-500 w-full text-center flex justify-center items-center  md:p-4 p-2 space-x-2 rounded cursor-pointer">
                      <span className="bg-white md:w-8 md:h-8 w-6 h-6 flex items-center justify-center rounded-full text-slate-800 md:text-base text-sm ">
                        2
                      </span>
                      <p className="text-slate-800 font-medium md:text-base text-sm">
                        Additional details
                      </p>
                    </div>
                  </div>

                  <div className="py-6">
                    <h1 className="capitalize md:text-xl text-lg text-white font-medium">
                      Step 2: enter experince and education
                    </h1>
                    {/* {JSON.stringify(this.state.fieldsUpdateProfile)} */}
                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-4 py-6">
                      <div className="space-y-2">
                        <div className="space-y-2">
                          <label className="text-sm text-white/80 capitalize ">
                            Main Specilization <b className="text-red-500">*</b>
                          </label>
                          <br />
                          <div className="flex space-x-6">
                            <div className="py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base">
                              <select
                                value={
                                  this.state.specilization1
                                    ? this.state.specilization1
                                    : null
                                }
                                onChange={this.inputChangeSpecilization1}
                                class="w-full bg-gray-800 flex items-center text-sm  cursor-pointer justify-between focus:outline-none text-white/80"
                                placeholder="Select specilization"
                              >
                                <option value="">Select</option>
                                <option value="Happiness coach">
                                  Happiness coach
                                </option>
                                <option value="Life Coach">Life Coach</option>
                                {/* <option value="Yoga Instructor" >Lecturer</option> */}
                                <option value="Psychologist">
                                  Psychologist
                                </option>
                                <option value="Clinical Psychologist">
                                  Clinical Psychologist
                                </option>
                                <option value="Mental Health Professional">
                                  Mental Health Professional
                                </option>
                                <option value="Counselling Psychologist">
                                  Counselling Psychologist
                                </option>
                                <option value="Handwriting Coach">
                                  Handwriting Coach
                                </option>
                                <option value="Diet Therapist & Lifestyle Coach">
                                  Diet Therapist & Lifestyle Coach
                                </option>
                                <option value="Gratitude-Happiness Coach">
                                  Gratitude-Happiness Coach
                                </option>
                                <option value="Facilitator & Life Coach">
                                  Facilitator & Life Coach
                                </option>
                                <option value="Others">Others</option>
                              </select>
                            </div>

                            <input
                              type="text"
                              onChange={this.inputChangeUpdate}
                              name="specilization2"
                              className="py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base"
                              placeholder="Enter other specilization"
                              value={
                                this.state.fieldsUpdateProfile &&
                                this.state.fieldsUpdateProfile["specilization2"]
                                  ? this.state.fieldsUpdateProfile[
                                      "specilization2"
                                    ]
                                  : ""
                              }
                            />
                            {this.state.errorsUpdateProfile &&
                            this.state.errorsUpdateProfile["specilization2"] ? (
                              <div className="invalid-feedback  text-red-500">
                                {
                                  this.state.errorsUpdateProfile[
                                    "specilization2"
                                  ]
                                }
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="space-y-2">
                          <label className="text-sm text-white/80 capitalize ">
                            working experince
                          </label>
                          <br />
                          <div className="py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base">
                            <select
                              value={
                                this.state.experience
                                  ? this.state.experience
                                  : null
                              }
                              onChange={this.inputChangeExperience}
                              class="w-full bg-gray-800 flex items-center text-sm  cursor-pointer justify-between focus:outline-none text-white/80"
                            >
                              <option value="Less Then One Year">
                                Less Then One Year
                              </option>
                              <option value="1+ years">1+ years</option>
                              <option value="2+ years">2+ years</option>
                              <option value="3+ years">3+ years</option>
                              <option value="4+ years">4+ years</option>
                              <option value="5+ years">5+ years</option>
                              <option value="6+ years">6+ years</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize ">
                          Please tell us something more about yourself
                        </label>
                        <br />
                        <textarea
                          rows={5}
                          placeholder="wirte here.."
                          onChange={this.inputChangeUpdate}
                          name="remark"
                          className="py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base"
                          defaultValue={""}
                          value={
                            this.state.fieldsUpdateProfile &&
                            this.state.fieldsUpdateProfile["remark"]
                              ? this.state.fieldsUpdateProfile["remark"]
                              : ""
                          }
                        />
                        {this.state.errorsUpdateProfile &&
                        this.state.errorsUpdateProfile["remark"] ? (
                          <div className="invalid-feedback  text-red-500">
                            {this.state.errorsUpdateProfile["remark"]}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-4  ">
                    <button
                      onClick={() => this.inputChangeExpertPreviousModal()}
                      className="bg-white/10 text-lg md:w-auto w-full font-medium px-12 py-2.5 rounded-full  items-center justify-center hover:text-white text-white/80 capitalize"
                    >
                      previous
                    </button>
                    <button
                      onClick={() => this.createExpertForm()}
                      className="bg-yellow-500 text-lg md:w-auto w-full font-medium px-12 py-2.5 rounded-full  items-center justify-center hover:text-white text-slate-800 capitalize"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" ">
                <div className="lg:w-5/6 w-11/12 mx-auto  bg-white/10 rounded-lg shadow-xl md:p-10 p-4  ">
                  <div className="flex md:flex-row flex-col w-full md:gap-10 gap-6">
                    <div className="capitalize bg-yellow-500 w-full text-center flex justify-center  items-center md:p-4 p-2 space-x-2 rounded cursor-pointer">
                      <span className="bg-white md:w-8 md:h-8 w-6 h-6 flex items-center justify-center text-sm rounded-full text-slate-800 md:text-base">
                        1
                      </span>
                      <p className="text-slate-800 font-medium md:text-base text-sm">
                        personal details
                      </p>
                    </div>
                    <div className="capitalize bg-white/10 w-full text-center flex justify-center items-center  md:p-4 p-2 space-x-2 rounded cursor-pointer">
                      <span className=" border-2   md:w-8 md:h-8 w-6 h-6 flex items-center justify-center text-sm rounded-full text-white md:text-base">
                        2
                      </span>
                      <p className="text-white font-medium md:text-base text-sm">
                        additional details
                      </p>
                    </div>
                  </div>

                  <div className="py-6">
                    <h1 className="capitalize md:text-xl text-lg text-white font-medium">
                      Step 1: enter personal details
                    </h1>
                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-4 py-6">
                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize ">
                          full name <b className="text-red-500">*</b>
                        </label>
                        <br />
                        <input
                          type="text"
                          name="fullName"
                          className=" py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base"
                          onChange={this.inputChangeUpdate}
                          value={
                            this.state.fieldsUpdateProfile &&
                            this.state.fieldsUpdateProfile["fullName"]
                              ? this.state.fieldsUpdateProfile["fullName"]
                              : ""
                          }
                        />
                        {this.state.errorsUpdateProfile &&
                        this.state.errorsUpdateProfile["fullName"] ? (
                          <div className="invalid-feedback  text-red-500">
                            {this.state.errorsUpdateProfile["fullName"]}
                          </div>
                        ) : null}
                      </div>

                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize">
                          language
                        </label>
                        <br />
                        <Multiselect
                          options={this.options}
                          onSelect={this.handleSelectedChanged}
                          selected={selected}
                          disableSearch={true}
                          renderHeader={true}
                          displayValue="label"

                          // value={this.state.selected ? this.state.selected : null}
                        />
                      </div>

                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize">
                          email <b className="text-red-500">*</b>
                        </label>
                        <br />
                        <input
                          type="text"
                          name="email"
                          className=" py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base"
                          onChange={this.inputChangeUpdate}
                          value={
                            this.state.fieldsUpdateProfile &&
                            this.state.fieldsUpdateProfile["email"]
                              ? this.state.fieldsUpdateProfile["email"]
                              : ""
                          }
                        />
                        {this.state.errorsUpdateProfile &&
                        this.state.errorsUpdateProfile["email"] ? (
                          <div className="invalid-feedback  text-red-500">
                            {this.state.errorsUpdateProfile["email"]}
                          </div>
                        ) : null}
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize ">
                          State
                        </label>
                        <br />
                        <div className="py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base">
                          <select
                            value={this.state.stateName && this.state.stateName}
                            onChange={this.inputChangeState}
                            className="w-full bg-gray-800  flex items-center text-sm  cursor-pointer justify-between focus:outline-none text-white/80"
                          >
                            <option value={""}>Select State</option>
                            <option value={"Andhra Pradesh"}>
                              Andhra Pradesh{" "}
                            </option>
                            <option value={"Arunachal Pradesh"}>
                              Arunachal Pradesh{" "}
                            </option>
                            <option value={"Assam"}>Assam</option>
                            <option value={"Bihar"}>Bihar</option>
                            <option value={"Chhattisgarh"}>Chhattisgarh</option>
                            <option value={"Goa"}>Goa</option>
                            <option value={"Gujarat"}>Gujarat</option>
                            <option value={"Haryana"}>Haryana</option>
                            <option value={"Himachal Pradesh"}>
                              Himachal Pradesh
                            </option>
                            <option value={"Jharkhand"}>Jharkhand</option>
                            <option value={"Karnataka"}>Karnataka</option>
                            <option value={"Kerala"}>Kerala</option>
                            <option value={"Madhya Pradesh"}>
                              Madhya Pradesh
                            </option>
                            <option value={"Maharashtra"}>Maharashtra </option>
                            <option value={"Manipur"}>Manipur</option>
                            <option value={"Meghalaya"}>Meghalaya</option>
                            <option value={"Mizoram"}>Mizoram</option>
                            <option value={"Nagaland"}>Nagaland</option>
                            <option value={"Odisha"}>Odisha</option>
                            <option value={"Punjab"}>Punjab</option>
                            <option value={"Rajasthan"}>Rajasthan</option>
                            <option value={"Sikkim"}>Sikkim</option>
                            <option value={"Tamil Nadu"}>Tamil Nadu </option>
                            <option value={"Telangana"}>Telangana</option>
                            <option value={"Tripura"}>Tripura</option>
                            <option value={"Uttar Pradesh	"}>
                              Uttar Pradesh{" "}
                            </option>
                            <option value={"Uttarakhand"}>Uttarakhand</option>
                            <option value={"West Bengal"}>West Bengal</option>
                          </select>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize ">
                          phone
                        </label>
                        <br />
                        <input
                          type="text"
                          name="phone"
                          className=" py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base"
                          onChange={this.inputChangeUpdate}
                          value={
                            this.state.fieldsUpdateProfile &&
                            this.state.fieldsUpdateProfile["phone"]
                              ? this.state.fieldsUpdateProfile["phone"]
                              : ""
                          }
                        />
                        {this.state.errorsUpdateProfile &&
                        this.state.errorsUpdateProfile["phone"] ? (
                          <div className="invalid-feedback  text-red-500">
                            {this.state.errorsUpdateProfile["phone"]}
                          </div>
                        ) : null}
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize">
                          city
                        </label>
                        <br />
                        <input
                          type="text"
                          name="city"
                          className=" py-2.5 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-800  rounded focus:outline-none text-white text-base"
                          onChange={this.inputChangeUpdate}
                          value={
                            this.state.fieldsUpdateProfile &&
                            this.state.fieldsUpdateProfile["city"]
                              ? this.state.fieldsUpdateProfile["city"]
                              : ""
                          }
                        />
                        {this.state.errorsUpdateProfile &&
                        this.state.errorsUpdateProfile["city"] ? (
                          <div className="invalid-feedback  text-red-500">
                            {this.state.errorsUpdateProfile["city"]}
                          </div>
                        ) : null}
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm text-white/80 capitalize">
                          gender
                        </label>
                        <br />
                        <div className="flex lg:space-x-20 space-x-10">
                          <label
                            for="radio1"
                            className="cursor-pointer flex items-center capitalize font-medium text-white/80 "
                          >
                            <input
                              id="radio1"
                              type="radio"
                              name="gender"
                              className="mr-2 -mt-0.5"
                            />
                            Male
                          </label>

                          <label
                            for="radio2"
                            className="cursor-pointer flex items-center capitalize font-medium text-white/80 "
                          >
                            <input
                              id="radio2"
                              type="radio"
                              name="gender"
                              className="mr-2 -mt-0.5"
                            />
                            Female
                          </label>
                          <label className="cursor-pointer flex items-center capitalize font-medium text-white/80 ">
                            <input
                              type="radio"
                              name="gender"
                              className="mr-2 -mt-0.5"
                            />
                            Others
                          </label>
                        </div>
                      </div>
                      {/* <div className="space-y-2">
                          <label className="text-sm text-gray-500 capitalize ">picture</label><br />
                          <input type="file" name className="border  w-full focus:outline-none p-2 rounded-lg" />
                        </div> */}
                    </div>
                  </div>
                  <div className="flex justify-end ">
                    <button
                      onClick={() => this.inputChangeExpertModal()}
                      type="button"
                      className="bg-yellow-500 text-lg md:w-auto w-full font-medium px-12 py-2.5 rounded-full  items-center justify-center hover:text-white text-slate-800"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn, otpSent, registeruser } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    registeruser,
    otpSent,
    users,
  };
}

export default connect(mapStateToProps)(withRouter(JoinUsasExpert));
