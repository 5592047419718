
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './index.css'; 
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import JoinUsasExpert from './pages/JoinUsasExpert';
import AdditionalDetails from './pages/AdditionalDetails';
import About from './pages/About';
import Landing from './pages/landing';
import HomeNFT from './pages/HomeNFT';
import Mint from './pages/Mint';
import NftStaked from './pages/NftStaked';
import NftDetails from './pages/Nft-details';
import Home from './pages/index'

function App() {
  return (
    <Router>
      <Switch>

        <Route exact path="/" children={<Home />} />
        <Route exact path="/contactus" children={<ContactUs />} />
        <Route exact path="/about" children={<About />} /> 
        <Route exact path="/PrivacyPolicy" children={<PrivacyPolicy />} />
        <Route exact path="/joinusasexpert" children={<JoinUsasExpert />} />
        <Route exact path="/additionaldetails" children={<AdditionalDetails />} />
        <Route exact path="/landing" children={<Landing />} />
        <Route exact path="/home" children={<HomeNFT />} />
        <Route exact path="/mint" children={<Mint />} /> 
        <Route exact path="/stake" children={<NftStaked />} /> 
        <Route exact path="/nftdetails" children={<NftDetails />} /> 


      </Switch>

    </Router>

  )
}

export default App;